<i18n src="./translations"></i18n>

<template>
  <div :class="[classes]" :data-preload="[$options.name]">
    <p class="brands-title">{{ $t('brand-filter-container.title') }}:</p>
    <div class="wrapper">
      <ul class="brands-options">
        <li
          v-for="(item, index) in optionsArr"
          v-show="shouldShowBrand(index)"
          :key="index"
          style="display: none;"
          :class="[
            'brand-item',
            {
              active: item.isActive,
            },
          ]"
          @click="handleClickBrand(item.alias, item.isActive)"
        >
          <AdvancedImage
            v-if="item.imageUrl"
            class="imageContainer"
            :src="item.imageUrl"
            :alt="item.title"
            :is-brand="true"
            :title="item.title"
            :lazy="true"
          />
          <span class="alternate-text">{{ item.title }}</span>
        </li>
        <li v-show="!isMobile" class="showMore-button">
          <span
            v-if="
              !isShowingMore && optionsArr.length > nrOfBrandFiltersDisplayed
            "
            class="brands-show show-more"
            @click="showMore"
          >
            {{ $t('brand-filter-container.show-more') }}
          </span>
          <span
            v-if="isShowingMore"
            class="brands-show show-less"
            @click="showLess"
          >
            {{ $t('brand-filter-container.show-less') }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import globalMixin from 'Libs/mixins/globalMixin';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';
import values from 'lodash/values';
import partition from 'lodash/partition';
import flatten from 'lodash/flatten';
import { mapState } from 'vuex';

export default {
  name: 'BrandFilterContainer',
  components: {
    AdvancedImage,
  },
  mixins: [globalMixin],
  props: {
    alias: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: cloneDeep(this.value),
      isShowingMore: false,
      nrOfBrandFiltersDisplayed: 5,
    };
  },
  computed: {
    ...mapState('core', {
      isMobile: ({ viewport }) => viewport.range !== 'desktop',
    }),
    hasDirtyData() {
      return !isEqual(this.value, this.localValue);
    },
    optionsArr() {
      return flatten(partition(values(this.options), ['isActive', true]));
    },
  },
  mounted() {
    this.$watch('value', (newValue) => {
      this.localValue = cloneDeep(newValue);
    });
  },
  methods: {
    shouldShowBrand(index) {
      //This prevents rendering the full list on the server side
      // and showing it for a sec on the client side
      if (this.$isServer) {
        return index < this.nrOfBrandFiltersDisplayed || this.isShowingMore;
      }

      return (
        index < this.nrOfBrandFiltersDisplayed ||
        this.isShowingMore ||
        this.isMobile
      );
    },
    showMore() {
      this.isShowingMore = true;
    },
    showLess() {
      this.isShowingMore = false;
    },
    handleClickBrand(brandAlias, isActive) {
      if (!isActive) {
        this.$emit('input', [...this.value, brandAlias]);
        return;
      }

      this.$emit(
        'input',
        this.value.filter((v) => v !== brandAlias)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';

.brandFilterContainer {
  background-color: var(--color-wild-sandish);
  padding: 0 var(--space-1--half) 0;

  @media #{$_mediaMDown} {
    padding: 0 var(--space-1--half);
  }

  .wrapper {
    display: flex;
    flex-wrap: nowrap;

    .brands-show {
      margin-left: 3px;
      width: 200px;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: var(--color-gold-drop);
      }
    }
  }

  .alternate-text {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 98px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;

    &:first-child {
      display: block;
    }
  }

  .advancedImage--failed-image {
    & + .alternate-text {
      display: block;
    }

    ::v-deep img {
      display: none;
    }
  }

  .brands-options {
    display: flex;
    flex-wrap: wrap;
  }

  .brands-title {
    margin-left: var(--space-0--half);
  }

  .brands-title,
  .brands-show {
    font-size: 14px;
    color: var(--color-nero);
    margin-bottom: 6px;
  }

  .showMore-button {
    margin-top: 34px;
    margin-left: var(--space-0--half);
  }

  .show-less {
    margin-bottom: 30px;
    display: inline-block;
  }

  .brand-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 97px;
    height: 50px;
    background: white;
    border: 1px solid white;
    margin-right: var(--space-1--half);
    margin-left: var(--space-0--half);
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;

    &:last-child {
      margin: 0;
    }

    &.active,
    &:hover {
      border: 1px solid var(--color-secondary);
    }
  }

  .content--narrow ::v-deep .brand-item {
    width: 99px;
  }

  ::v-deep .advancedImage {
    width: 100px;
  }

  ::v-deep .brandImage {
    max-width: 100px;
    max-height: 42px;
    width: auto;
    height: auto;
    margin: 0 auto;
    padding: 2px 6px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    position: relative;
  }

  ::v-deep .imageContainer picture {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }

  ::v-deep .label__value {
    width: 180px;
    word-break: break-word;
    white-space: normal;
  }
}

@media #{$_mediaMDown} {
  .brandFilterContainer {
    .brands-options {
      overflow-x: auto;
      flex-wrap: initial;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
    }

    .brands-title {
      font-size: 12px;
      margin-left: 3px;
    }

    .brand-item {
      &:nth-child(8n) {
        margin-right: 16px;
      }
    }
  }
}
</style>
