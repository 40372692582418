<i18n src="./translations"></i18n>

<template>
  <div
    class="caris-combined"
    :class="[
      classes,
      isExpanded ? 'caris-combined--expanded' : 'caris-combined--collapsed',
      { 'caris-combined--tall': !isExpanded && isCarSelected },
    ]"
    :data-preload="[$options.name]"
  >
    <button
      class="caris-combined__toggle-button"
      :class="[
        isExpanded
          ? 'caris-combined__toggle-button--expanded'
          : 'caris-combined__toggle-button--collapsed',
        {
          'caris-combined__toggle-button--tall': !isExpanded && isCarSelected,
        },
      ]"
      @click="toggleExpanded"
    >
      <template v-if="!isExpanded">
        <span v-if="!isCarSelected" class="caris-combined__collapsed-headline">
          {{ $t('caris-combined.select_car') }}
        </span>
        <p v-else class="caris-combined__selection-name selection-name">
          <span class="selection-name__model">
            {{ selectionNameModel }}
          </span>
          {{ selectionNameRest }}
        </p>
      </template>
      <Icon
        class="caris-combined__toggle-icon"
        :class="
          isExpanded
            ? 'caris-combined__toggle-icon--expanded'
            : 'caris-combined__toggle-icon--collapsed'
        "
        name="chevron_right"
        width="24px"
        height="24px"
      />
    </button>
    <template v-if="isCarSelected">
      <CarisSelection
        class="caris-combined__selection"
        :selection-name="carDisplayName"
        @select-new-vehicle="handleSelectNewVehicle"
      />
      <div
        v-if="!isUserLoggedIn"
        class="caris-combined__mobile-separator caris-combined__mobile-separator--with-text"
      >
        {{ $t('caris-combined.separator_text') }}
      </div>
      <div
        v-else
        class="caris-combined__mobile-separator caris-combined__mobile-separator--with-line"
      ></div>
      <CarisMyGarage
        v-if="isUserLoggedIn"
        class="caris-combined__my-garage"
        :vehicles="garageVehicles"
        :is-fetching="isFetching"
      />
      <CarisAuthenticationMessage
        v-else
        class="caris-combined__authentication-message"
      />
    </template>
    <template v-else>
      <CarisHsnTsn
        v-if="!carisLicenseSearch && !carisNatCodeSearch"
        class="caris-combined__hsn-tsn"
        :parent-form="hsnTsnForm"
        :validate-form="false"
        :qa-class-name-prefix="'qa-caris'"
        :gtm-class-name="'gtm--caris-'"
        @select="handleHsnTsnSelect"
      />

      <CarisLicenseNewHeader
        v-if="carisLicenseSearch"
        ref="licensePlateSearch"
        :validate-form="false"
        :parent-form="hsnTsnForm"
        @select="handleHsnTsnSelect"
      />

      <CarisNatCodeHeader
        v-if="carisNatCodeSearch"
        ref="natCodePlateSearch"
        :validate-form="false"
        :parent-form="hsnTsnForm"
        @select="handleHsnTsnSelect"
      />

      <div class="caris-combined__mobile-separator mobile-separator">
        {{ $t('caris-combined.separator_text') }}
      </div>

      <CarisSelectionForm
        class="caris-combined__selection-form"
        :selections="selections"
        :manufacturers="manufacturers"
        :models="models"
        :types="types"
        :qa-class-name-prefix="'qa-caris'"
        :gtm-class-name="'gtm--caris-'"
        @change="handleVehicleSelectionChange"
      />
    </template>

    <CarisInformation
      class="caris-combined__information"
      :opening-hours="contactUsInfo.openingHours"
      :phone-number="contactUsInfo.phoneNumber"
    />

    <CarisSelectVehicleDialog
      :is-active="isCarisSelectVehicleDialogActive"
      :parent-form="hsnTsnForm"
      :parent-selections="selections"
      @close="handleNewVehicleDialogClose"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';

import globalMixin from 'Libs/mixins/globalMixin';
import carisSelectionFormMixin from 'Libs/mixins/carisSelectionFormMixin';
import LocalStorage from 'Libs/helpers/local-storage';

import Icon from 'Components/00-generated/Icon';
import CarisHsnTsn from 'Components/02-molecules/caris-hsn-tsn/CarisHsnTsn';
import CarisSelectionForm from 'Components/02-molecules/caris-selection-form/CarisSelectionForm';
import CarisLicenseNewHeader from 'Components/02-molecules/caris-license-new-header/CarisLicenseNewHeader.vue';
import CarisInformation from 'Components/02-molecules/caris-information/CarisInformation';
import CarisMyGarage from 'Components/02-molecules/caris-my-garage/CarisMyGarage';
import CarisSelection from 'Components/02-molecules/caris-selection/CarisSelection';
import CarisAuthenticationMessage from 'Components/02-molecules/caris-authentication-message/CarisAuthenticationMessage';
import CarisSelectVehicleDialog from 'Components/03-organisms/caris-select-vehicle-dialog/CarisSelectVehicleDialog';
import CarisNatCodeHeader from 'Components/03-organisms/caris-nat-code-header/CarisNatCodeHeader';

export default {
  name: 'CarisCombined',
  components: {
    CarisSelection,
    CarisInformation,
    CarisHsnTsn,
    CarisSelectionForm,
    CarisLicenseNewHeader,
    CarisMyGarage,
    CarisAuthenticationMessage,
    CarisSelectVehicleDialog,
    Icon,
    CarisNatCodeHeader,
  },
  mixins: [globalMixin, carisSelectionFormMixin],
  data() {
    return {
      fetchClusters: true,
      isCarisSelectVehicleDialogActive: false,
      hsnTsnForm: {
        hsnValue: null,
        tsnValue: null,
        licensePlateValue: null,
        natCodeValue: null,
      },
    };
  },
  computed: {
    ...mapGetters('core', {
      config: 'getSalesChannelConfig',
      isBusinessCustomer: 'isBusinessCustomer',
    }),
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
    }),
    ...mapState('carSelection', {
      garageVehicles: (state) => state.garageVehicles,
      isFetching: (state) => state.fetching,
      isUserLoggedIn: ({ data }) => data.isLoggedIn,
      carDisplayName: ({ data }) => data.carDisplayName,
      isExpanded: ({ expanded }) => expanded,
      isDialogActive: ({ isDialogActive }) => isDialogActive,
    }),
    contactUsInfo() {
      return {
        phoneNumber: this.isBusinessCustomer
          ? this.config?.b2BCustomerServicePhoneNumber
          : this.config?.customerServicePhoneNumber,
        openingHours: this.isBusinessCustomer
          ? this.config?.b2BCustomerServiceOpeningHours
          : this.config?.customerServiceOpeningHours,
      };
    },
    carisLicenseSearch() {
      return this.config?.carisLicenseSearch || false;
    },
    carisNatCodeSearch() {
      return this.config?.carisNatCodeSearch || false;
    },
    isCarSelected() {
      return !!this.carDisplayName;
    },
    selectionNameModel() {
      return `${this.carDisplayName.split(' (')[0]}, `;
    },
    selectionNameRest() {
      const splitSelectionName = this.carDisplayName.split(', ');
      return splitSelectionName.slice(1).join(', ');
    },
  },
  watch: {
    isDialogActive(isDialogActive) {
      if (
        isDialogActive &&
        isDialogActive !== this.isCarisSelectVehicleDialogActive
      ) {
        this.handleSelectNewVehicle();
      }
    },
    isCarisSelectVehicleDialogActive(isActive) {
      isActive ? this.openWidget() : this.closeWidget();
    },
  },
  mounted() {
    this.checkForGarageAddition();
    if (this.isUserLoggedIn) {
      this.doRetrieveGarageVehicles();
    }
  },
  methods: {
    ...mapMutations('carSelection', {
      openWidget: 'CARIS_DIALOG__OPEN',
      closeWidget: 'CARIS_DIALOG__CLOSE',
    }),
    ...mapActions('carSelection', [
      'doRetrieveGarageVehicles',
      'updateSaveToGarage',
      'toggleExpanded',
    ]),
    handleTypeChange() {
      this.hsnTsnForm.hsnValue = null;
      this.hsnTsnForm.tsnValue = null;
      this.hsnTsnForm.licensePlateValue = null;
      this.hsnTsnForm.natCodeValue = null;
      this.isCarisSelectVehicleDialogActive = true;
    },
    handleNewVehicleDialogClose() {
      this.isCarisSelectVehicleDialogActive = false;
    },
    handleHsnTsnSelect() {
      this.selections.manufacturer = null;
      this.selections.model = null;
      this.selections.type = null;
      this.isCarisSelectVehicleDialogActive = true;
    },
    handleSelectNewVehicle() {
      this.hsnTsnForm = {
        hsnValue: null,
        tsnValue: null,
        licensePlateValue: null,
        natCodeValue: null,
      };
      this.selections = { manufacturer: null, model: null, type: null };
      this.isCarisSelectVehicleDialogActive = true;
    },
    async checkForGarageAddition() {
      const localStorage = new LocalStorage();
      const saveToGarageVehicle = localStorage.getItem(
        'CARIS_COMBINED__SAVE_TO_GARAGE_VEHICLE'
      );

      if (saveToGarageVehicle) {
        if (this.isUserLoggedIn) {
          this.updateSaveToGarage(JSON.parse(saveToGarageVehicle));
          await this.$nextTick();
          this.isCarisSelectVehicleDialogActive = true;
        } else if (
          !['/customer/login', '/customer/registration'].includes(
            location.pathname
          )
        ) {
          this.updateSaveToGarage({ ktypnr: null, garageName: null });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.caris-combined {
  display: grid;
  position: relative;
  grid-template-columns: 270px 520px 1fr;
  background: var(--color-support);
  width: 100%;
  max-height: 180px;
  transition:
    max-height var(--time-XS) ease-in,
    padding-top var(--time-XS) ease-in;
  overflow: hidden;

  @include page-size-padding(var(--space-2));

  // [ozlui] increased selector specificity to not use an !important expression
  &--expanded#{&} {
    max-height: 180px;

    @media #{$_mediaL} {
      max-height: 180px;
    }
  }
}

.caris-combined__collapsed-headline {
  font-weight: bold;
  font-size: var(--font-size-SM);
  line-height: var(--font-size-SM);
  letter-spacing: -0.16px;
  color: var(--color-white);
}

.caris-combined__toggle-button {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  width: 24px;
  padding: 0;
  position: absolute;
  top: var(--space-1--half);
  right: var(--space-2);
  cursor: pointer;
}

.caris-combined__toggle-icon {
  height: 24px;
  width: 24px;
  color: var(--color-white);

  &--expanded {
    transform: rotate(-90deg);
  }

  &--collapsed {
    transform: rotate(90deg);
  }
}

.caris-combined__toggle-button + *:first-of-type {
  transition: margin-top 0.08s ease-in;
  margin-top: 0;
}

.caris-combined__selection {
  width: 320px;
  min-width: 320px;
  padding-right: 50px;
}

.caris-combined__selection-name {
  font-size: var(--font-size-SM);
  line-height: var(--font-size-SM);
  color: var(--color-white);
}

.selection-name__model {
  font-weight: bold;
  text-transform: uppercase;
}

.caris-combined__hsn-tsn {
  width: 270px;
  min-width: 270px;
}

.caris-combined__mobile-separator {
  display: none;
  justify-content: space-around;
  font-weight: bold;
  font-size: var(--font-size-M);
  line-height: var(--font-size-ML);
  color: var(--color-secondary);
  margin: var(--space-2) 0;
  height: 20px;

  &::before,
  &::after {
    content: '.';
    color: transparent;
    width: 32%;
    border-top: 1px solid var(--color-secondary);
    margin-top: 10px;
  }

  &--with-line {
    margin: var(--space-4) 0 var(--space-4) -#{var(--space-2)};

    &::before,
    &::after {
      width: 50%;
    }
  }
}

.caris-combined__selection-form {
  min-width: 470px;
  width: 470px;
  margin-left: 50px;
}

.caris-combined__information {
  width: 100%;
  grid-column: 3 / span 1;
  margin-left: 94px;
}

.caris-combined__my-garage,
.caris-combined__authentication-message {
  margin-left: 50px;
  width: 470px;
  min-width: 470px;

  @media #{$_mediaLDown} {
    width: auto;
    min-width: 235px;
  }
}

@media (max-width: #{$_widthXXLFrom}) {
  $left-column-width: 220px;
  $middle-column-width: 1fr;

  .caris-combined {
    grid-template-columns: #{$left-column-width} #{$middle-column-width} 1fr;
  }

  .caris-combined__selection {
    width: $left-column-width;
    min-width: $left-column-width;
    padding-right: 0;
  }

  .caris-combined__hsn-tsn {
    width: $left-column-width;
    min-width: $left-column-width;
  }

  .caris-combined__my-garage {
    margin-left: 20px;
  }

  .caris-combined__selection-form {
    width: unset;
    min-width: unset;
    margin-left: 20px;
  }

  .caris-combined__information {
    margin-left: 36px;
  }
}

@media #{$_mediaL} {
  .caris-combined__information {
    margin-left: 15px;
  }
}

@media #{$_mediaMDown} {
  .caris-combined {
    display: flex;
    flex-direction: column;
    max-height: 48px;

    // [ozlui] increased selector specificity to not use an !important expression
    &--collapsed#{&} {
      .caris-combined__toggle-button + *:first-of-type {
        margin-top: var(--space-4);
      }
    }

    &--expanded#{&} {
      max-height: 100vh;
    }
  }

  .caris-combined__toggle-button {
    display: flex;

    &--collapsed {
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      padding: 9px var(--space-2);
      text-align: left;
    }
  }

  .caris-combined__hsn-tsn {
    width: 100%;
    min-width: unset;
  }

  .caris-combined__mobile-separator {
    display: flex;
  }

  .caris-combined__selection-form {
    margin: 0;
  }

  .caris-combined__information {
    display: none;
  }

  .caris-combined__selection {
    width: 100%;
  }

  .caris-combined__authentication-message,
  .caris-combined__my-garage {
    margin: 0;
    width: 100%;
    min-width: unset;
  }
}
</style>
