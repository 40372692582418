var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdp-table",class:[_vm.classes, ("pdp-table--" + _vm.modifier)],attrs:{"data-preload":[_vm.$options.name]}},[(_vm.headline)?_c('span',{staticClass:"pdp-table__headline"},[_vm._v("\n    "+_vm._s(_vm.headline)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.isCompact)?_c('ul',{staticClass:"pdp-table__list"},[_vm._l((_vm.renderedLines),function(line,index){return [(_vm.rowModifier.indices.includes(index))?_c('li',{key:(index + "-slot"),staticClass:"pdp-table__list-item pdp-table__list-item--slot"},[_vm._t("row-modifier-extra")],2):_vm._e(),_vm._v(" "),_c('li',{key:(index + "-list-item"),staticClass:"pdp-table__list-item",class:[
          _vm.rowModifier.indices.includes(index)
            ? ("pdp-table__list-item--" + (_vm.rowModifier.modifier))
            : 'pdp-table__list-item--regular',
          _vm.previousSiblingIndices.includes(index) &&
          !_vm.rowModifier.indices.includes(index)
            ? 'pdp-table__list-item--prev-sibling-to-modified'
            : '' ]},_vm._l((_vm.columns),function(ref){
        var label = ref.label;
        var key = ref.key;
return _c('span',{key:line[key],staticClass:"pdp-table__list-item-cell",class:{
            'pdp-table__list-item-cell--full-row': key === _vm.fullRowColumnKey,
          }},[_c('span',{staticClass:"pdp-table__list-item-cell-header"},[_vm._v(" "+_vm._s(label))]),_vm._v(" "),_c('span',{staticClass:"pdp-table__list-item-cell-value"},[_vm._v("\n            "+_vm._s(line[key])+"\n          ")])])}),0)]})],2):_c('table',{staticClass:"pdp-table__table"},[(_vm.showHeaders)?_c('thead',{staticClass:"pdp-table__table-header"},[_c('tr',{staticClass:"pdp-table__header-row"},_vm._l((_vm.columns),function(ref,columnIndex){
          var label = ref.label;
return _c('th',{key:label,staticClass:"pdp-table__table-header-cell",style:(_vm.cellStyling(columnIndex))},[_vm._v("\n          "+_vm._s(label)+"\n        ")])}),0)]):_vm._e(),_vm._v(" "),_c('tbody',{staticClass:"pdp-table__table-body"},[_vm._l((_vm.renderedLines),function(line,index){return [(_vm.rowModifier.indices.includes(index))?_c('tr',{key:(index + "-slot-row"),staticClass:"pdp-table__table-row pdp-table__table-row--slot-row",class:[
            _vm.rowModifier.indices.includes(index)
              ? ("pdp-table__table-row--" + (_vm.rowModifier.modifier))
              : 'pdp-table__table-row--regular' ]},[_c('td',{staticClass:"pdp-table__table-cell pdp-table__table-cell--slot-cell",attrs:{"colspan":_vm.columns.length}},[_vm._t("row-modifier-extra")],2)]):_vm._e(),_vm._v(" "),_c('tr',{key:index,staticClass:"pdp-table__table-row",class:[
            _vm.rowModifier.indices.includes(index)
              ? ("pdp-table__table-row--" + (_vm.rowModifier.modifier))
              : 'pdp-table__table-row--regular',
            _vm.previousSiblingIndices.includes(index) &&
            !_vm.rowModifier.indices.includes(index)
              ? 'pdp-table__table-row--prev-sibling-to-modified'
              : '' ]},_vm._l((_vm.columns),function(ref,columnIndex){
          var key = ref.key;
return _c('td',{key:line[key],staticClass:"pdp-table__table-cell",style:(_vm.cellStyling(columnIndex))},[_vm._t(key,function(){return [_vm._v("\n              "+_vm._s(line[key])+"\n            ")]},{"line":line})],2)}),0)]})],2)]),_vm._v(" "),(!_vm.showAll && _vm.loadLimit && _vm.loadLimit < _vm.lines.length)?_c('button',{staticClass:"pdp-table__show-all-button",attrs:{"type":"button"},on:{"click":_vm.toggleShowAll}},[_vm._v("\n    "+_vm._s(_vm.showAllLabel)+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }