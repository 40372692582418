var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumbs--section",class:_vm.classes,attrs:{"data-preload":[_vm.$options.name]}},[(!_vm.isBrandPage)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.seoScript)}}):_vm._e(),_vm._v(" "),_vm._l((_vm.breadcrumbsItems),function(item,index){return [_c('span',{key:("breadcrumbs-" + index),staticClass:"item-divider",class:{
        'item-divider-masked-link': _vm.isBrandPage,
        'item-divider--mobile':
          item.url && index === _vm.breadcrumbsItems.length - 2,
      }},[_vm._v("\n      >\n    ")]),_vm._v(" "),(!_vm.isBrandPage)?_c(item.url ? 'a' : 'span',{key:("breadcurmb-text-" + index),tag:"component",staticClass:"item",class:{
        link: item.url,
        'link--clickable': item.url && !!_vm.onClickHandler,
        'link--leaf': item.url && index === _vm.breadcrumbsItems.length - 1,
        'link--mobile': item.url && index === _vm.breadcrumbsItems.length - 2,
        label: !item.url,
      },attrs:{"href":_vm.getHref(item),"title":_vm.itemTitle(item)},domProps:{"textContent":_vm._s(_vm.itemTitle(item))},on:{"click":function($event){item.url && _vm.handleClick(item)}}}):_c('MaskedLink',{key:("breadcrumb-text-" + index),staticClass:"item masked",class:{
        link: item.url,
        'link--clickable': item.url && !!_vm.onClickHandler,
        'link--leaf': item.url && index === _vm.breadcrumbsItems.length - 1,
        'link--mobile': item.url && index === _vm.breadcrumbsItems.length - 2,
        label: !item.url,
      },attrs:{"redirect-to":_vm.getHref(item),"title":_vm.itemTitle(item),"html":_vm.itemTitle(item)},on:{"click-handler":function($event){item.url && _vm.clickHandlerMaskedLink(item)}}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }