var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"pdp-vehicle-notification",class:("pdp-vehicle-notification--" + (_vm.compatibilityClasses[_vm.vehicleCompatibility])),attrs:{"data-preload":[_vm.$options.name]}},[(_vm.vehicleCompatibility === 'NOT_SELECTED')?_c('button',{class:['pdp-vehicle-notification__link', _vm.gtmClassName + '-link'],attrs:{"type":"button"},on:{"click":_vm.openWidget}},[_vm._v("\n    "+_vm._s(_vm.$t('pdp-vehicle-notification.select-a-car'))+"\n  ")]):_vm._e(),_vm._v("\n\n  "+_vm._s(_vm.$t(_vm.compatibilityTranslations[_vm.vehicleCompatibility]))+"\n\n  "),(
      ['SELECTED_CAR_OK', 'SELECTED_CAR_NOT_OK'].includes(
        _vm.vehicleCompatibility
      ) && _vm.vehicleName
    )?[_c('br'),_vm._v("\n    "+_vm._s(_vm.vehicleName)+"\n  ")]:_vm._e(),_vm._v(" "),(
      _vm.vehicleCompatibility === 'SELECTED_CAR_NOT_OK' &&
      _vm.relatedItemsLink !== '/' &&
      _vm.relatedItemsLink !== ''
    )?_c('MaskedLink',{staticClass:"pdp-vehicle-notification__link",attrs:{"redirect-to":_vm.relatedItemsLink,"text":_vm.$t('pdp-vehicle-notification.to-fitting-items')}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }