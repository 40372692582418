var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{ref:"alternative-products-dialog",staticClass:"alternative-products-dialog",attrs:{"modifier":"alternative-products-dialog","title":_vm.$t('alternative-products-dialog.title'),"active":_vm.active},on:{"hide":_vm.closeAction}},[_c('div',{staticClass:"alternative-products-dialog-container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("\n        "+_vm._s(_vm.$t(
            ("alternative-products-dialog.description" + (_vm.isParentAvailable() ? '' : '_unavailable'))
          ))+"\n      ")]),_vm._v(" "),(_vm.product)?_c('div',{staticClass:"product-container"},[_c('div',{staticClass:"image-container"},[(_vm.product.images && _vm.product.images.length > 0)?_c('AdvancedImage',{staticClass:"alternative-products-dialog-product-image",attrs:{"src":_vm.product.images[0].src,"alt":_vm.product.images[0].alt || _vm.product.name,"config":{ q: 80, w: 180 }}}):_vm._e(),_vm._v(" "),(_vm.product.brandLogoUrl)?_c('AdvancedImage',{staticClass:"alternative-products-dialog-brand-image",attrs:{"src":_vm.product.brandLogoUrl,"alt":_vm.product.brandName || '',"config":{ q: 80, w: 180 }}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"description-container"},[_c('strong',{staticClass:"product-title",on:{"click":function($event){return _vm.openProduct()}}},[_vm._v(_vm._s(_vm.product.name))]),_vm._v(" "),_c('ul',[(_vm.vehicleName)?_c('li',[_c('Icon',{attrs:{"name":"checkmark_green"}}),_vm._v(" "),_c('span',{staticClass:"has-checkmark"},[_vm._v("\n                "+_vm._s(_vm.$t('alternative-products-dialog.compatible_with'))+_vm._s(_vm.vehicleName))])],1):_vm._e(),_vm._v(" "),_c('li',[_c('Icon',{attrs:{"name":"checkmark_green"}}),_vm._v(" "),_c('span',{staticClass:"has-checkmark"},[_vm._v("\n                "+_vm._s(_vm.$t('alternative-products-dialog.similar_price_range'))+"\n              ")])],1),_vm._v(" "),_c('li',[_c('Icon',{attrs:{"name":"checkmark_green"}}),_vm._v(" "),_c('span',{staticClass:"has-checkmark"},[_vm._v("\n                "+_vm._s(_vm.$t('alternative-products-dialog.available_immediately'))+"\n              ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"price-container"},[_c('PriceInfo21',{ref:"priceInfo",attrs:{"modifier":"slim","product":_vm.product}})],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"footer"},[_vm._v("\n      "+_vm._s(_vm.$t(
          ("alternative-products-dialog.footer-text" + (_vm.isParentAvailable() ? '' : '_unavailable'))
        ))+"\n      "),_c('div',{staticClass:"buttons-container"},[_c('Button',{attrs:{"on-click":_vm.cancelAction,"title":_vm.$t(
              ("alternative-products-dialog.cancel-title" + (_vm.isParentAvailable() ? '' : '_unavailable'))
            ),"modifiers":['secondary']}}),_vm._v(" "),_c('Button',{attrs:{"on-click":_vm.confirmAction,"title":_vm.$t(
              ("alternative-products-dialog.confirm-title" + (_vm.isParentAvailable() ? '' : '_unavailable'))
            ),"modifiers":['primary']}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }