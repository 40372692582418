import { render, staticRenderFns } from "./BranchesAvailabilityLink.vue?vue&type=template&id=7df2f0d3&scoped=true&"
import script from "./BranchesAvailabilityLink.vue?vue&type=script&lang=js&"
export * from "./BranchesAvailabilityLink.vue?vue&type=script&lang=js&"
import style0 from "./BranchesAvailabilityLink.vue?vue&type=style&index=0&id=7df2f0d3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7df2f0d3",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F01-atoms%2Fbranches-availability-link%2FBranchesAvailabilityLink.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports