import { render, staticRenderFns } from "./BrandFilterContainer.vue?vue&type=template&id=07ed446e&scoped=true&"
import script from "./BrandFilterContainer.vue?vue&type=script&lang=js&"
export * from "./BrandFilterContainer.vue?vue&type=script&lang=js&"
import style0 from "./BrandFilterContainer.vue?vue&type=style&index=0&id=07ed446e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ed446e",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F03-organisms%2Fbrand-filter-container%2FBrandFilterContainer.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports