var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.classes,
    {
      'sortingDropdown--opened': _vm.isOpened,
    } ],attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"header",on:{"click":_vm.toggleContainer}},[_c('div',{staticClass:"header__name"},[_vm._v("\n      "+_vm._s(_vm.activeTitle)+"\n    ")]),_vm._v(" "),_c('div',{class:['header__icon', { 'header__icon--up': _vm.isOpened }]},[_c('Icon',{attrs:{"name":"arrow_drop_down","width":"9","height":"9"}})],1)]),_vm._v(" "),(_vm.isOpened)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.handleClose,
      isActive: _vm.isOpened,
      events: ['click'],
    }),expression:"{\n      handler: handleClose,\n      isActive: isOpened,\n      events: ['click'],\n    }"}],staticClass:"container"},[_c('div',{staticClass:"container__body"},[_c('SortingOptionList',{attrs:{"is-mobile":false,"options":_vm.sorting.options,"active-option":_vm.activeSorting,"name":_vm.sorting.alias},on:{"input":_vm.click}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }