<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div class="bannersRow">
      <div
        v-if="primaryImage"
        class="primary qa-CmsPrimarySecondaryBanners__primaryLink"
        :title="primaryImage.link.title"
        @click="
          trackPromoClick({
            href: primaryImage.link.href,
            position: primaryImage.position,
            callback: () => redirectTo(primaryImage.link.href),
          })
        "
      >
        <AdvancedImage v-bind="primaryImageConfig()" />
        <!-- // Will be enabled conditionally -->
        <!-- <Button
          :title="$t('cms-primary-secondary-banners.show_more')"
          modifier="primary"
        /> -->
      </div>

      <div
        v-if="secondaryImage && secondaryImage.link && secondaryImage.link.href"
        class="secondary-image"
        :class="{
          secondary: primaryImage,
          primary: !primaryImage,
          'qa-CmsPrimarySecondaryBanners__secondaryLink': true,
        }"
        :title="secondaryImage.link.title"
        @click="
          trackPromoClick({
            href: secondaryImage.link.href,
            position: secondaryImage.position,
            callback: () => redirectTo(secondaryImage.link.href),
          })
        "
      >
        <AdvancedImage v-bind="secondaryImageConfig()" />

        <Button
          :title="$t('cms-primary-secondary-banners.show_more')"
          :modifiers="['primary', 'nowrap']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import imageMixin from 'Libs/mixins/imageMixin.js';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';
import Button from 'Components/01-atoms/button/Button';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapActions } from 'vuex';

export default {
  name: 'CmsPrimarySecondaryBanners',
  components: { AdvancedImage, Button },
  mixins: [globalMixin, imageMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      required: true,
    },
    primaryImage: {
      type: Object,
      default: null,
    },
    secondaryImage: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.trackBannersInView();
  },
  methods: {
    ...mapActions('dataLayer', ['trackPromoClick', 'trackBannersInView']),
    redirectTo(url) {
      window.location.href = url;
    },
    primaryImageConfig() {
      return {
        lazy: false,
        isLazyComponent: false,
        src: this.primaryImage.sources.tablet,
        alt: this.primaryImage.alt,
        width: 932,
        height: 434,
        sources: [
          {
            media: 'S_DOWN',
            src: this.primaryImage.sources.mobile,
            width: 480,
            height: 255,
          },
          {
            media: 'L_DOWN',
            src: this.primaryImage.sources.tablet,
            width: 1000,
            height: 532,
          },
          {
            src: this.primaryImage.sources.desktop,
            width: 932,
            height: 434,
          },
        ],
        class: 'primaryPicture',
        referenceImgClass: 'imageInner qa-imageInner',
      };
    },
    secondaryImageConfig() {
      return {
        isLazyComponent: false,
        referenceImgClass: 'imageInner',
        src: this.secondaryImage.sources.desktop,
        alt: this.secondaryImage.alt,
        sources: [
          {
            src: this.secondaryImage.sources.desktop,
          },
        ],
      };
    },
  },
  serverCacheKey: ({ id, updatedAt }) => {
    /* istanbul ignore next */
    return `${id}::${updatedAt}`;
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.cmsPrimarySecondaryBanners {
  display: block;
  width: 100%;
  margin-top: 0;

  @media #{$_mediaLDown} {
    margin-top: -24px;
  }
}

.bannersRow {
  display: flex;
  margin: 0 -16px;

  @media (min-width: 1300px) {
    margin: 0;
  }

  @media #{$_mediaMDown} {
    margin-top: var(--space-3);
  }
}

.primary,
.secondary {
  position: relative;
  max-height: 500px;
  overflow: hidden;
  cursor: pointer;

  @media #{$_mediaLDown} {
    &:hover > .button {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
    }
  }
}

.primary {
  flex-grow: 1;
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);

  @media #{$_mediaLDown} {
    height: auto;
    // min-height: 52vw;
  }
}

.secondary {
  flex-shrink: 0;
  width: 320px;
  margin-left: var(--space-2);
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);

  @media #{$_mediaLDown} {
    display: none;
  }
}

.button {
  position: absolute !important;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  &--hover {
    background-color: var(--color-tory-blue);
    border-color: var(--color-tory-blue);
  }
}

::v-deep .imageInner {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: top !important;
}
</style>
