<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div class="wrapper">
      <div
        v-if="showSelector"
        class="selectWrapper"
        :class="{
          'selectWrapper--focused': selected,
          'selectWrapper--no-divider': !hasVerticalDivider,
          'selectWrapper--darker': darker,
          'input-error': isMaxQuantityReached,
        }"
      >
        <label v-if="hasLabel" class="label" :for="id">
          {{ label }}
        </label>

        <select
          :id="id"
          v-model="selectedValue"
          class="select qa-select"
          :class="{
            'select--focused': selected,
            'select--no-label': !hasLabel,
          }"
          @change="onSelectChange()"
          @focus="onFocus"
          @blur="onBlur"
        >
          <option
            v-for="(choice, index) in choices"
            v-show="parseInt(choice.value) <= maxQuantity"
            :key="index"
            :value="choice.value"
            :selected="value === choice.value"
          >
            {{ choice.label }}
          </option>

          <option
            v-if="threshold && maxQuantity >= threshold"
            :value="threshold"
          >
            {{ threshold }}<span v-if="maxQuantity !== threshold">+</span>
          </option>
        </select>

        <ArrowDropDownSvg class="icon" :original="true" height="7" width="8" />
      </div>
      <div
        v-else
        class="inputWrapper"
        :class="{
          'inputWrapper--focused': selected,
          'input-error': isMaxQuantityReached,
        }"
      >
        <label class="label" :for="id">
          {{ label }}
        </label>

        <input
          :id="id"
          v-model.number="inputValue"
          class="input"
          :class="{ 'input--focused': selected }"
          pattern="\d*"
          maxLength="3"
          type="number"
          @change="onInputChange()"
          @focus="onFocus"
          @blur="onBlur"
        />

        <ReloadSvg class="icon" :original="true" height="30" width="25" />
      </div>

      <slot></slot>
    </div>

    <div v-if="isMaxQuantityReached" class="max-quantity-error">
      {{ $t('quantity-selector.max-quantity-error') }}
    </div>
  </div>
</template>

<script>
import ArrowDropDownSvg from 'Components/00-generated/ArrowDropDownSvg';
import ReloadSvg from 'Components/00-generated/ReloadSvg';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState, mapActions } from 'vuex';
import { axios } from 'Services/AjaxService';

export default {
  name: 'QuantitySelector',
  components: {
    ArrowDropDownSvg,
    ReloadSvg,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      default: () => ({
        productId: '',
        sku: '',
        availability: '',
      }),
      required: true,
    },
    darker: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    hasVerticalDivider: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    choices: {
      type: Array,
      default: () => [
        {
          label: '1',
          value: '1',
        },
        {
          label: '2',
          value: '2',
        },
        {
          label: '3',
          value: '3',
        },
        {
          label: '4',
          value: '4',
        },
        {
          label: '5',
          value: '5',
        },
        {
          label: '6',
          value: '6',
        },
        {
          label: '7',
          value: '7',
        },
        {
          label: '8',
          value: '8',
        },
        {
          label: '9',
          value: '9',
        },
      ],
    },
    threshold: {
      type: Number,
      default: 10,
    },
    isMinicart: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedValue: 1,
    inputValue: 1,
    showSelector: true,
    selected: false,
    id: null,
    maxQuantity: 999,
    isMaxQuantityReached: false,
    prevAvailability: '', // To monitor Availability changes for Minicart since it gets updated through the store
  }),
  computed: {
    ...mapState('core', {
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
      viewport: ({ viewport: { range } }) => range,
    }),
    ...mapState('miniCart', {
      minicartProducts: (state) => state.data?.minicart?.products || null,
    }),
    stockAvailabilityUrl() {
      return this.frontFacingPath('stock.ajax.availability');
    },
  },
  watch: {
    value(value) {
      if (this.selectedValue !== value || this.inputValue !== value) {
        this.selectedValue = value || 1;
        this.inputValue = value || 1;
        this.showSelector = this.value <= 9;
        this.prevAvailability = this.product?.availability;
      }
    },
    minicartProducts(newMinicartProducts) {
      if (this.isMinicart && newMinicartProducts) {
        this.getQuantity();
      }
    },
  },
  mounted() {
    this.selectedValue = this.value || 1;
    this.inputValue = this.value || 1;
    this.id = this.generateUID('quantitySelector-');

    this.showSelector = this.value <= 9;
    this.prevAvailability = this.product?.availability;
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    checkStockChanges(newAvailability, newMaxStock) {
      this.maxQuantity = newMaxStock ? newMaxStock : this.maxQuantity;

      if (
        this.selectedValue > this.maxQuantity ||
        this.inputValue > this.maxQuantity
      ) {
        this.selectedValue = this.maxQuantity;
        this.inputValue = this.maxQuantity;
        this.isMaxQuantityReached = true;
      }

      const isMinicartAvailabilityUpdated =
        this.isMinicart &&
        this.prevAvailability &&
        newAvailability !== this.prevAvailability;

      if (
        this.product.availability !== newAvailability ||
        isMinicartAvailabilityUpdated
      ) {
        this.product.availability = newAvailability;
        this.prevAvailability = newAvailability;

        this.addMessage({
          icon: 'info-filled',
          text: this.$t('quantity-selector.stock-availability-toast'),
          type: 'light-blue',
          style:
            this.viewport === 'mobile' && this.isMaxQuantityReached
              ? 'margin-bottom: 70px'
              : '',
        });
      }
    },
    async fetchQuantity() {
      const { data } = await axios.post(
        this.stockAvailabilityUrl,
        {
          products: [
            {
              sku: this.product?.sku,
              demanded_quantity: this.showSelector
                ? this.selectedValue
                : this.inputValue,
            },
          ],
        },
        {
          headers: { 'X-Sales-Channel-ID': this.salesChannelId },
        }
      );

      return data?.find((item) => item.sku === this.product.sku);
    },
    async getQuantity() {
      this.isMaxQuantityReached = false;

      let productStock = this.isMinicart
        ? this.minicartProducts.find(
            (product) => product.sku === this.product.sku
          )
        : null;

      if (!productStock) productStock = await this.fetchQuantity();

      if (!productStock) return;

      this.checkStockChanges(
        productStock.availability || productStock.availabilityStatus,
        productStock.maxStock
      );
    },
    async onSelectChange() {
      if (!this.isMinicart) await this.getQuantity();

      this.showSelector = parseInt(this.selectedValue, 10) <= 9;
      this.inputValue = this.selectedValue;

      this.emitChanges();
    },
    async onInputChange() {
      if (!this.isMinicart) await this.getQuantity();

      const parsedInput = parseInt(this.inputValue, 10);

      if (!Number.isInteger(parsedInput) || parsedInput <= 0) {
        this.inputValue = this.choices[0].value;
      }
      this.showSelector = parsedInput <= 9;

      if (this.showSelector) {
        this.selectedValue = this.choices.find(
          (choice) => choice.value.toString() === this.inputValue.toString()
        )
          ? this.inputValue
          : this.choices[0].value;
      }

      this.emitChanges();
    },
    emitChanges() {
      this.$emit('onQuantityChange', {
        productId: this.product?.productId,
        quantity: this.showSelector ? this.selectedValue : this.inputValue,
      });
    },
    onFocus() {
      this.selected = true;
    },
    onBlur() {
      this.selected = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.quantitySelector {
  min-width: 72px;
  margin-bottom: var(--space-1);

  --row-height: 48px;

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    .selectWrapper,
    .inputWrapper {
      width: 82px;
      margin-right: var(--space-1);
      margin-top: var(--space-1);

      .select,
      .input {
        height: var(--row-height);
      }
    }
  }

  .max-quantity-error {
    color: var(--color-rebranding-semantic-red);
    margin-top: var(--space-1);
    line-height: var(--space-2--half);
  }

  //here//

  &--error {
    .error,
    .label {
      color: var(--color-red);
    }

    .selectWrapper {
      &::before {
        border-left-color: var(--color-red);
      }
    }
  }

  &--disabled {
    pointer-events: none;

    .selectWrapper,
    .inputWrapper {
      &::before {
        content: none;
      }
    }

    .select,
    .input {
      color: var(--color-rolling-stone);
    }

    .icon {
      display: none;
    }
  }

  &--showInput {
    .selectWrapper {
      display: none;
    }

    .inputWrapper {
      display: block;
    }
  }

  &--pending {
    .inputWrapper {
      .icon {
        fill: var(--color-tangerine);
      }
    }
  }

  &--centeredIcon {
    .icon {
      right: 15px;

      &.reloadSvg {
        right: 7px;
      }
    }
  }

  &--centeredIcon {
    .icon {
      right: 15px;

      &.reloadSvg {
        right: 7px;
      }
    }
  }

  .select,
  .input {
    padding-right: 35px;
  }

  .icon {
    right: 20px;
  }

  &--slim {
    .selectWrapper,
    .inputWrapper {
      &::before {
        content: '';
        width: 35px;
      }
    }

    .icon {
      right: 14px;
    }
  }
}

select::-ms-expand {
  display: none;
}

.selectWrapper,
.inputWrapper {
  position: relative;
  cursor: pointer;

  &.input-error {
    .input,
    .select {
      border-color: var(--color-rebranding-semantic-red) !important;
    }
  }
}

.selectWrapper {
  @include themeColor(
    color,
    var(--color-gold-drop),
    var(--color-endeavour),
    var(--color-chathamsblue)
  );

  &::before,
  .icon {
    pointer-events: none;
  }

  &--no-divider {
    &::before {
      border: none;
    }
  }

  &--darker {
    .select {
      color: var(--color-nero) !important;
      border-color: var(--color-silver-lining) !important;
    }
  }
}

.inputWrapper {
  .icon {
    fill: var(--color-black);
  }
}

.label {
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;

  font-size: var(--font-size-XS);
  color: var(--color-black);

  &--required::after {
    content: '*';
  }
}

.select,
.input {
  appearance: none;
  outline: none;
  background: var(--color-white);

  padding-right: 53px;
  padding-left: 8px;
  height: 40px;
  padding-top: 14px;
  width: 100%;
  min-width: 82px;
  transition: all var(--time-S);

  font-weight: bold;
  font-size: var(--font-size-M);
  line-height: 16px;

  border: 1px solid var(--color-alto) !important;
  border-radius: var(--border-radius-default) !important;

  @include themeColor(
    color,
    var(--color-gold-drop),
    var(--color-endeavour),
    var(--color-chathamsblue)
  );

  &--focused {
    border: 1px solid var(--color-chathamsblue) !important;
  }
}

.select {
  border-color: var(--color-red);
  -webkit-appearance: none;
  cursor: pointer;

  &:focus::-ms-value {
    background: var(--color-white);

    color: var(--color-primary);
  }

  &--no-label {
    padding-top: 0;
  }
}

.input {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);

  transition: fill var(--time-S) ease-in-out;

  &.reloadSvg {
    right: 11px;
  }
}

.error,
.message {
  padding-top: var(--space-0--half);
  padding-left: var(--space-XXS);

  color: var(--color-black--primary);
  font-size: var(--font-size-S);
  line-height: 16px;
}

@media print {
  .quantitySelector {
    &--disabled {
      width: 60px;

      .select,
      .input {
        width: 60px;
      }
    }
  }
}
</style>
