var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal","data-preload":_vm.$options.name,"disabled":_vm.isPortalDisabled || _vm.isPortalDisabledForiOS}},[(_vm.active)?_c('article',{staticClass:"simple-dialog",class:[
      _vm.parentClassName,
      _vm.classes,
      {
        background: _vm.background,
        top: _vm.position === 'top',
        center: _vm.position === 'center',
        bottom: _vm.position === 'bottom',
      } ]},[_c('div',{staticClass:"simple-dialog__header"},[(_vm.$slots.headerPrefix)?[_vm._t("headerPrefix")]:[_c('span',{staticClass:"simple-dialog__title"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),(_vm.message)?_c('p',{staticClass:"simple-dialog__message"},[_vm._v(_vm._s(_vm.message))]):_vm._e()],_vm._v(" "),_c('button',{staticClass:"simple-dialog__close-button qa-simpleDialogCloseButton",class:_vm.gtmClassName ? _vm.gtmClassName + 'closeButton' : '',attrs:{"type":"button"},on:{"click":_vm.handleClose}},[_c('CloseSvg',{staticClass:"simple-dialog__close-icon"})],1)],2),_vm._v(" "),(_vm.$slots.default)?_c('div',{staticClass:"simple-dialog__body"},[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.action ? _vm.action.enabled !== false : false)?_c('Button',{staticClass:"simple-dialog__action-button qa-simpleDialogActionButton",attrs:{"title":_vm.action.title,"modifier":_vm.action.modifier,"on-click":_vm.handleActionClick,"type":"button"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.active)?_c('div',{staticClass:"simple-dialog-shadow",class:{ background: _vm.background }}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }