import { render, staticRenderFns } from "./InputBase.vue?vue&type=template&id=f96584cc&scoped=true&"
import script from "./InputBase.vue?vue&type=script&lang=js&"
export * from "./InputBase.vue?vue&type=script&lang=js&"
import style0 from "./InputBase.vue?vue&type=style&index=0&id=f96584cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f96584cc",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F01-atoms%2Fform%2Finput%2FInputBase.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports