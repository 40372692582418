var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('div',{staticClass:"caris-select",class:{ 'caris-select--disabled': _vm.isDisabled }},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedOption && !_vm.isFocused),expression:"!selectedOption && !isFocused"}],staticClass:"caris-select__label",attrs:{"for":("caris-select__select#" + _vm.escapedLabel)}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption),expression:"selectedOption"}],ref:"select",staticClass:"caris-select__select",class:[
        {
          'caris-select__select--enabled': !_vm.selectedOption && !_vm.isDisabled,
          'caris-select__select--disabled': _vm.isDisabled,
          'caris-select__select--active': _vm.selectedOption,
        },
        _vm.qaClassName + 'select',
        _vm.gtmClassName ],attrs:{"id":("caris-select__select#" + _vm.escapedLabel)},on:{"focus":_vm.setFocus,"blur":_vm.clearFocus,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedOption=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.optionGroups),function(optionGroup){return _c('optgroup',{key:optionGroup.label,staticClass:"caris-select__option-group",attrs:{"label":optionGroup.label}},_vm._l((optionGroup.options),function(option){return _c('option',{key:option.value,staticClass:"caris-select__option",domProps:{"value":option}},[_vm._v("\n          "+_vm._s(_vm._f("removeBrTag")(option.label ||
            (option.labels ? option.labels.join(', ') : '-')))+"\n        ")])}),0)}),0),_vm._v(" "),_c('Icon',{staticClass:"caris-select__icon",attrs:{"name":"chevron_right","width":"18","height":"18"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }