import { render, staticRenderFns } from "./PdpVehicleNotification.vue?vue&type=template&id=3c924710&scoped=true&"
import script from "./PdpVehicleNotification.vue?vue&type=script&lang=js&"
export * from "./PdpVehicleNotification.vue?vue&type=script&lang=js&"
import style0 from "./PdpVehicleNotification.vue?vue&type=style&index=0&id=3c924710&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c924710",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fpdp-vehicle-notification%2FPdpVehicleNotification.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports