<template>
  <div
    v-show="entitiesList.length"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <span v-if="title" class="title">
      {{ title }}
    </span>

    <div :class="['tableContainer', { tablePadding: !hasBorder }]">
      <table>
        <tbody>
          <tr :class="{ noBorder: !hasBorder }">
            <th v-for="(column, index) in columnsList" :key="index">
              {{ column.label }}
            </th>
          </tr>

          <tr
            v-for="(entity, index) in entitiesList"
            :key="index"
            :class="{ noBorder: !hasBorder }"
          >
            <td
              v-for="columnKey in columnsList.map((column) => column.key)"
              :key="columnKey"
            >
              <slot :name="columnKey" :entity="entity">
                {{ entity[columnKey] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="showLoadMore && entitiesList.length"
        class="loadMore"
        :class="{ noBorder: !hasBorder }"
        @click="loadMoreHandler()"
      >
        <LoadingSpinner
          v-if="isLoadingMore"
          :dimensions="{ width: '20', height: '20' }"
          modifier="greyTrace"
        />
        <ArrowDropDownSvg v-else class="triggerIcon" width="9" height="9" />
        {{ loadMoreLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import ArrowDropDownSvg from 'Components/00-generated/ArrowDropDownSvg';
import LoadingSpinner from 'Components/01-atoms/loading-spinner/LoadingSpinner';

import { axios } from 'Services/AjaxService';

export default {
  name: 'Table',
  components: { ArrowDropDownSvg, LoadingSpinner },
  mixins: [globalMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Array,
      default: () => [],
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    loadMoreLabel: {
      type: String,
      default: '',
    },
    isAjax: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    tableKey: {
      type: String,
      default: '',
    },
    intialRowsLimit: {
      type: Number,
      default: Infinity,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    transform: {
      type: Function,
      default: (entities) => entities,
    },
  },
  data() {
    return {
      entitiesList: this.transform(this.entities),
      columnsList: this.columns,
      showLoadMore: this.loadMore,
      isLoadingMore: false,
    };
  },
  mounted() {
    if (this.url && this.isAjax) {
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      const params = { limit: this.intialRowsLimit };
      const { data } = await axios.get(this.url, { params });

      const entities = data[this.tableKey].entities || [];
      const count = data[this.tableKey].count;

      const transformedList = this.transform(entities);
      this.entitiesList = transformedList.slice(0, this.intialRowsLimit);

      this.columnsList = data[this.tableKey].columns;
      this.showLoadMore = count > this.entitiesList.length;

      if (!this.entitiesList.length) {
        this.columnsList = [];
        this.$emit('tableIsEmpty', this.tableKey);
      }
    },
    async loadMoreHandler() {
      this.isLoadingMore = true;
      const { data } = await axios.get(this.url);

      const newEntitiesList = this.transform(data[this.tableKey].entities);

      if (newEntitiesList.length >= this.entitiesList.length) {
        this.entitiesList = newEntitiesList;
        this.showLoadMore = false;
        this.isLoadingMore = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.table {
  font-size: var(--font-size-SM);
}

.tableContainer {
  overflow: auto;
  max-height: 530px;
}

.tablePadding {
  padding: {
    left: 7px;
    right: 7px;
  }
}

.title {
  display: block;
  color: var(--color-chathamsblue);
  font-size: var(--font-size-M);
  font-weight: bold;
  line-height: 40px;
}

.link {
  @include themeColor(
    color,
    var(--color-tory-blue),
    var(--color-endeavour),
    var(--color-chathamsblue)
  );
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

tr,
.loadMore {
  box-sizing: border-box;
  border: 1px solid var(--color-alt);
  height: 39px;
  line-height: 23px;
  padding: 4%;

  .LoadingSpinner {
    margin-right: var(--space-1);
    margin-top: var(--space-0--half);
  }
}

.noBorder {
  border : {
    left: none;
    right: none;
  }
}

th {
  font-weight: bold;
  font-size: var(--font-size-SM);
}

td {
  vertical-align: top;

  &:last-child {
    white-space: nowrap;
  }
}

th,
td,
.loadMore {
  text-align: left;
  padding: 8px;
  padding-left: 20px;
}

.loadMore {
  border-top: 0;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.triggerIcon {
  ::v-deep path {
    fill: var(--color-secondary);
  }

  margin-right: 10px;
  margin-left: -4px;
}

@media #{$_mediaMDown} {
  table {
    font-size: 11px;
  }

  th,
  .loadMore {
    font-size: var(--font-size-S);
  }

  .title {
    font-size: var(--font-size-SM);
  }
}
</style>
