import { axios } from '../../../services/AjaxService';
import TrackingHandler from '../../../libs/helpers/tracking';

// temporary workaround to highlight the active category in top nav
const getMatchedMenuItemIndex = ({ menuItems, baseString }) => {
  const matches = menuItems.map((menuItem) =>
    baseString.includes(menuItem.url)
  );
  return matches.indexOf(true);
};

const highlightSubmenu = (menuItems) => {
  const matchedIndex = getMatchedMenuItemIndex({
    menuItems,
    baseString: window.location.pathname,
  });
  if (matchedIndex !== -1) {
    menuItems[matchedIndex].selected = true;
  }
};

export const selectMenuItem = async (
  { commit, rootState, state },
  menuItem
) => {
  if (!menuItem) {
    commit('SET_ACTIVE_MENU_ITEM', null);
    return;
  }

  if (state.isDesktopOpen && menuItem.selected && !menuItem.topCategories) {
    return;
  }

  TrackingHandler.track({
    data: {
      event: 'navigation_click',
      categoryKey: menuItem.name || 'topCategories',
    },
  });

  const endpoint = rootState.core.fetchedUrls[
    'search.ajax.menu.subtree'
  ].replace('{menuName}', 'category-tree');
  const shouldFetch =
    !menuItem.topCategories &&
    menuItem.childrenCount !== menuItem.menuItems.length;

  commit('SELECT_ROOT_MENU', menuItem);
  commit('SET_ACTIVE_MENU_ITEM', menuItem);

  if (shouldFetch) {
    const headers = { 'x-front-facing-url': menuItem.url };

    commit('SET_ACTIVE_MENU_ITEM_FETCH', { fetching: true });

    const response = await axios.get(endpoint, { headers });
    const { menuItems } = response.data;
    highlightSubmenu(menuItems);
    commit('SET_MENU_ITEM_CHILDREN', { menuItem, children: menuItems });
  } else {
    highlightSubmenu(menuItem.menuItems);
  }
};

export const saveLastSelectedItemIndex = ({ commit }, index) => {
  commit('SET_LAST_SELECTED_MENU_ITEM_INDEX', index);
};

export const closePanel = ({ commit }) => {
  commit('SET_ACTIVE_MENU_ITEM', null);
};

export const selectTopCategories = ({ commit, state }) => {
  commit('SET_ACTIVE_MENU_ITEM', state.data.menuItems[0]);
};

export const selectMenu = async ({ commit, rootState, state, dispatch }) => {
  await fetchMenuIfNeeded({ commit, rootState, state });

  commit('SET_ACTIVE_MENU_ITEM', {});
  dispatch('highlightSelectedMenuItemMobile');
};

export const closeMenu = ({ commit, state }) => {
  commit('CLOSE_DESKTOP_MENU');
  commit('SET_ACTIVE_MENU_ITEM', null);
  commit('SELECT_ROOT_MENU', state.data.menuItems[0]);
};

const fetchMenuIfNeeded = async ({ commit, rootState, state }) => {
  const shouldFetch = state.data.menuItems.length === 0;

  if (shouldFetch) {
    commit('LOADING_STATE_MENU_ITEMS', true);
    const endpoint = rootState.core.fetchedUrls['search.ajax.menu.navigation'];
    const response = await axios.get(endpoint);
    let rootMenuItems = {
      menuItems: response.data.menu_items || response.data.menuItems,
      topCategories:
        response.data.top_categories || response.data.topCategories,
    };

    commit('SET_ROOT_MENU_ITEMS', rootMenuItems);
    commit('LOADING_STATE_MENU_ITEMS', false);
  }
};

export const toggleMenu = async ({ commit, rootState, state, dispatch }) => {
  if (state.isDesktopOpen) {
    closeMenu({ commit, state });
  } else {
    await fetchMenuIfNeeded({ commit, rootState, state });

    commit('OPEN_DESKTOP_MENU');
    const matchedMenuItemIndex = getMatchedMenuItemIndex({
      menuItems: state.data.menuItems,
      baseString: window.location.pathname,
    });
    const resultedIndex =
      matchedMenuItemIndex !== -1 ? matchedMenuItemIndex : 0;
    dispatch('selectMenuItem', state.data.menuItems[resultedIndex]);
  }

  if (state.expanded && state.isDesktopOpen) {
    commit('carSelection/CARIS_WIDGET__CLOSE', null, { root: true });
  } else {
    if (rootState.carSelection.autoExpand) {
      commit('carSelection/CARIS_WIDGET__OPEN', null, { root: true });
    }
  }
};

export const highlightSelectedMenuItemMobile = (
  { commit, state },
  parentIndex
) => {
  let menuItemIndex = parentIndex;

  if (!parentIndex) {
    menuItemIndex = getMatchedMenuItemIndex({
      menuItems: state.data.menuItems,
      baseString: window.location.pathname,
    });
  }

  const resultedIndex = menuItemIndex !== -1 ? menuItemIndex : 0;

  commit('SELECT_ROOT_MENU', state.data.menuItems[resultedIndex]);
};

export const mobileMenuOpen = ({ commit, dispatch }) => {
  dispatch('highlightSelectedMenuItemMobile');
  commit('OPEN_MOBILE_NAV');
};

export const closeMobileNavigation = ({ commit }) => {
  commit('SET_ACTIVE_MENU_ITEM', null);
  setTimeout(() => {
    commit('CLOSE_MOBILE_NAV');
  }, 200);
};
