var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.classes,
    _vm.lazyClass,
    ("advancedImage--" + _vm.mode),
    {
      'advancedImage--failed-image': _vm.computedImageSource === _vm.noImageSVG,
      'advancedImage--static-component': !_vm.isLazyComponent,
    } ],attrs:{"data-preload":[_vm.$options.name]}},[_c('picture',[_vm._l((_vm.transformedSources),function(source,index){return _c('Fragment',{key:index},[_c('source',{attrs:{"media":source.media,"srcset":source.srcSetWebP,"type":"image/webp","width":source.width,"height":source.height}}),_vm._v(" "),_c('source',{attrs:{"media":source.media,"srcset":source.srcSetOriginal,"width":source.width,"height":source.height}})])}),_vm._v(" "),_c('img',{ref:"img",class:_vm.imgClasses,style:({
        objectFit: ("" + _vm.objectFit),
      }),attrs:{"loading":_vm.isLazyComponent ? 'lazy' : 'eager',"src":_vm.computedImageSource,"alt":_vm.alt,"width":_vm.width,"height":_vm.height},on:{"click":function($event){return _vm.handleImageClick()},"load":_vm.onLoad,"error":_vm.handleError}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }