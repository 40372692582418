import { render, staticRenderFns } from "./CarisMyGarage.vue?vue&type=template&id=e98f8938&scoped=true&"
import script from "./CarisMyGarage.vue?vue&type=script&lang=js&"
export * from "./CarisMyGarage.vue?vue&type=script&lang=js&"
import style0 from "./CarisMyGarage.vue?vue&type=style&index=0&id=e98f8938&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e98f8938",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fcaris-my-garage%2FCarisMyGarage.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports