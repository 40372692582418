import { render, staticRenderFns } from "./CatalogCategorySearchDropdown.vue?vue&type=template&id=771b40db&scoped=true&"
import script from "./CatalogCategorySearchDropdown.vue?vue&type=script&lang=js&"
export * from "./CatalogCategorySearchDropdown.vue?vue&type=script&lang=js&"
import style0 from "./CatalogCategorySearchDropdown.vue?vue&type=style&index=0&id=771b40db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "771b40db",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F03-organisms%2Fcatalog-category-search-dropdown%2FCatalogCategorySearchDropdown.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports