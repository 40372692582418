<i18n src="./translations"></i18n>

<template>
  <portal
    to="login-modal"
    :data-preload="$options.name"
  >
    <div
      v-if="!isLoggedIn && !isExcludedPath"
      v-show="showModal"
      class="overlay qa-LoginModal__overlay"
    >
      <DialogFrame
        class="frame"
        :title="$t('login-modal.title')"
        :on-close-handler="() => setCoolDown()"
      >
        <span class="modal-subtitle">
          {{$t('login-modal.subtitle.part1')}}
          <b>{{$t('login-modal.subtitle.part2')}}</b>
           {{'&nbsp;' + $t('login-modal.subtitle.part3')}}
        </span>
        <LoginForm
          is-no-redirect
        />
      </DialogFrame>
    </div>
  </portal>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import DialogFrame from 'Components/01-atoms/dialog-frame/DialogFrame.vue';
import LoginForm from 'Components/02-molecules/login-form/LoginForm.vue';
import { mapState } from 'vuex';

export default {
  name: 'LoginModal',
  components: { DialogFrame, LoginForm },
  mixins: [globalMixin],
  data() {
    return {
      showModal: false,
      excludedPathsRegex: new RegExp('\\b(customer|shop|checkout)\\b'),
    };
  },
  computed: {
    ...mapState('core', {
      isLoggedIn: ({ requestData }) =>
        requestData.isLoggedIn ? requestData.isLoggedIn : false,
      visitorId: ({ requestData }) => requestData.visitorId,
      requestUrl: ({ requestData }) => requestData.requestUrl,
    }),
    isExcludedPath() {
      // [andrei] Do not display login modal for the mentioned route paths. (customer / shop / checkout)
      return this.excludedPathsRegex.test(this.requestUrl);
    },
  },
  mounted() {
    let coolDown = JSON.parse(sessionStorage.getItem('LOGIN_MODAL_COOLDOWN'));
    if (!coolDown || coolDown.visitorId !== this.visitorId) {
      this.showModal = true;
    } else {
      this.showModal = coolDown.timeStamp < Date.now();
    }
  },
  methods: {
    setCoolDown() {
      this.showModal = false;
      /**
       * When the user presses the 'x' button a cool down of one hour is set
       * so the pop-up is not shown too many times.
       * The cool down is stored in the SessionStorage and is checked whenever the route changes.
       * ! $nextTick is used to prevent delay when closing the pop-up, as sometimes it would take a few moments to set the object
       * in sessionStorage and then close the dialog.
       */
      this.$nextTick(() =>sessionStorage.setItem(
        'LOGIN_MODAL_COOLDOWN',
        JSON.stringify({
          visitorId: this.visitorId,
          timeStamp: Date.now() + 3600000 // Add one hour cool down
        })
      ));
    },
  }
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.overlay {
  position: fixed;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 100%;
  max-height: calc(100vh - 40px);
  max-width: 480px;
  z-index: 40;
  scroll-padding-top: 50px;

  ::v-deep article .body {
    padding: var(--space-3) var(--space-2) var(--space-3) var(--space-2);
  }
}

b {
  font-weight: bold;
}

.modal-subtitle {
  display: flex;
  width: 100%;
  padding-bottom: var(--space-3);
}

@media #{$_mediaSDown} {
  .frame {
    max-height: calc(100vh - 40px);
  }
}
</style>
