import { render, staticRenderFns } from "./PriceSavings.vue?vue&type=template&id=63e47e1b&"
import script from "./PriceSavings.vue?vue&type=script&lang=js&"
export * from "./PriceSavings.vue?vue&type=script&lang=js&"
import style0 from "./PriceSavings.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F01-atoms%2Fprice-savings%2FPriceSavings.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports