import { render, staticRenderFns } from "./PlpProduct21.vue?vue&type=template&id=3b62e7fd&scoped=true&"
import script from "./PlpProduct21.vue?vue&type=script&lang=js&"
export * from "./PlpProduct21.vue?vue&type=script&lang=js&"
import style0 from "./PlpProduct21.vue?vue&type=style&index=0&id=3b62e7fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b62e7fd",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fplp-product-21%2FPlpProduct21.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "../../03-organisms/product-details-21/translations?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fplp-product-21%2FPlpProduct21.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports