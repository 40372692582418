// TODO: add aliases to node
import { axios } from '../../../services/AjaxService';
import debounce from 'lodash/debounce';
import qs from 'qs';
import { has } from 'lodash';

const fadeOverlayOut = (context, timeout = 300) => {
  context.commit('SET_OVERLAY_FADING_ON');

  setTimeout(() => {
    context.commit('SET_OVERLAY_OFF');
    context.commit('SET_OVERLAY_FADING_OFF');
  }, timeout);
};

export const updateSearchTerm = (context, { target }) => {
  context.commit('UPDATE_SEARCH_TERM', target.value);
  if (target.value === '') {
    context.commit('SET_SEARCH_COMPLETION_TERMS', []);
  }
};

export const updateSearchCompletionTerms = async (context, { term, url }) => {
  const { data } = await axios.get(url, { params: { search: term } });

  if (data) {
    context.commit('SET_SEARCH_COMPLETION_TERMS', data.completions);
  }
};

export const clearSearchBar = (context) => {
  context.commit('UPDATE_SEARCH_TERM', '');
  context.commit('SET_SEARCH_COMPLETION_TERMS', []);
};

export const toggleAccountWidget = (context) => {
  context.commit('TOGGLE_ACCOUNT_WIDGET');
};

export const closeAccountWidget = (context) => {
  context.commit('CLOSE_ACCOUNT_WIDGET');
};

export const openAccountWidget = (context) => {
  context.commit('OPEN_ACCOUNT_WIDGET');
};

export const openMobileSearch = (context) => {
  context.commit('OPEN_MOBILE_SEARCH_BAR');
  context.commit('SET_OVERLAY_ON');
};

export const closeMobileSearch = (context) => {
  context.commit('CLOSE_MOBILE_SEARCH_BAR');
};

export const openMobileSideNav = (context) => {
  context.commit('OPEN_MOBILE_SIDENAV');
  context.commit('SET_OVERLAY_ON');
};

export const closeMobileSideNav = (context) => {
  context.commit('CLOSE_MOBILE_SIDENAV');
  fadeOverlayOut(context);
};

export const closeAllOverlays = (context) => {
  context.commit('CLOSE_MOBILE_SEARCH_BAR');
  context.commit('CLOSE_MOBILE_SIDENAV');
  fadeOverlayOut(context);
};

export const toggleMiniCart = (context) => {
  context.commit('TOGGLE_MINI_CART');
};

export const closeMiniCart = (context) => {
  context.commit('CLOSE_MINI_CART');
};

export const closeMiniCartAndNotification = (context) => {
  context.commit('CLOSE_MINI_CART');
  context.commit('CLOSE_LATEST_ADDED_NOTIFICATION');
};

const debouncedCloseNotification = debounce((context) => {
  context.commit('CLOSE_LATEST_ADDED_NOTIFICATION');
}, 4000);

export const showLatestAddedNotification = (context, { productId }) => {
  context.commit('OPEN_LATEST_ADDED_NOTIFICATION', productId);

  /**
   * Prevent firing the close event multiple times
   * This can lead to a bad experience when:
   * 1. the user buys an item
   *     --> notification 1 is visible (an event to close it will be fired in 4s)
   * 2. the user closes notification 1 and buys another item very quicly
   *    --> notification 2 appears and another event is scheduled to be fired in 4s
   * 3. 4s have passed and the first event is fired, closing the notification 2 even if
   *    it has not been visible for 4 entire seconds
   */
  debouncedCloseNotification(context);
};

export const addDiscountCode = async (
  {
    rootState: {
      core: { fetchedUrls },
    },
    commit,
  },
  { discountCode }
) => {
  const url = fetchedUrls['checkout.ajax.cart.voucher.add'];
  const payload = {
    source: 'url',
    discountCode,
  };

  const result = await axios.post(url, qs.stringify(payload));

  if (has(result, 'data.headline')) {
    commit('SET_DISCOUNT', {
      show: true,
      fields: { ...result.data },
    });
  }
};

export const checkDiscountCode = async (
  {
    rootState: {
      core: {
        fetchedUrls,
        salesChannel: { defaultLocale },
      },
    },
    commit,
  },
  { discountCode }
) => {
  const url = fetchedUrls['checkout.ajax.cart.voucher.popup'];

  const result = await axios.get(url, { params: { discountCode } });
  if (has(result, 'data.popup')) {
    const locale = defaultLocale.replace('_', '');
    const {
      popupHeader: headline,
      popupContent: content,
      popupFinePrint: finePrint,
    } = result.data.popup;

    const headlineKey = Object.keys(headline).find(
      (key) => key.toLowerCase() === locale.toLowerCase()
    );
    const contentKey = Object.keys(content).find(
      (key) => key.toLowerCase() === locale.toLowerCase()
    );
    const finePrintKey = Object.keys(finePrint).find(
      (key) => key.toLowerCase() === locale.toLowerCase()
    );

    commit('SET_DISCOUNT', {
      show: true,
      fields: {
        headline: headline[headlineKey],
        content: content[contentKey],
        finePrint: finePrint[finePrintKey],
      },
    });
  }
};

export const showUnavailableProductsDialog = (context, productsData) => {
  if (productsData.products.length > 0) {
    context.commit('SHOW_UNAVAILABLE_PRODUCTS_DIALOG', productsData);
  }
};
