var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.classes,
    {
      'catalogDropdownContainer--opened': _vm.isOpened,
      'catalogDropdownContainer--manyItems': Object.keys(_vm.options).length > 6,
      'qa-catalogFilter': true,
    } ],attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"header",on:{"click":_vm.toggleContainer}},[_c('div',{staticClass:"header__name",attrs:{"title":_vm.title}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('div',{class:['header__icon', { 'header__icon--up': _vm.isOpened }]},[_c('Icon',{attrs:{"name":"arrow_drop_down","width":"9","height":"9"}})],1)]),_vm._v(" "),(_vm.isOpened)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.handleSubmit,
      isActive: _vm.isOpened,
      events: ['click'],
    }),expression:"{\n      handler: handleSubmit,\n      isActive: isOpened,\n      events: ['click'],\n    }"}],staticClass:"container"},[_c('div',{staticClass:"container__body"},[_c('CatalogFilterOptionList',{attrs:{"options":_vm.options},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})],1),_vm._v(" "),_c('div',{staticClass:"container__footer"},[(_vm.hasDirtyData)?_c('Button',{staticClass:"applyButton button--primary qa-catalogFilter__apply",attrs:{"modifiers":['full', 'short'],"on-click":_vm.handleSubmit,"title":_vm.$t('catalog-dropdown-container.apply')}}):_c('Button',{staticClass:"closeButton button--secondary qa-catalogFilter__close",attrs:{"modifiers":['full', 'short'],"title":_vm.$t('catalog-dropdown-container.close'),"on-click":_vm.handleClose}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }