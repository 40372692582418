import { render, staticRenderFns } from "./CatalogFilters.vue?vue&type=template&id=8cc15f74&scoped=true&"
import script from "./CatalogFilters.vue?vue&type=script&lang=js&"
export * from "./CatalogFilters.vue?vue&type=script&lang=js&"
import style0 from "./CatalogFilters.vue?vue&type=style&index=0&id=8cc15f74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cc15f74",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F03-organisms%2Fcatalog-filters%2FCatalogFilters.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports