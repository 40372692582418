<i18n src="./translations"></i18n>
<template>
  <div
    class="default-template"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <portal-target multiple name="modal" />
    <portal-target name="product-cart-group" />
    <portal-target name="mobile-navigation" />
    <portal-target name="mobile-categories" />
    <portal-target name="carisFilter" />
    <portal-target name="mobile-filter" />
    <portal-target name="login-modal" class="qa_LoginModal__portal" />

    <LoginModal v-if="isAlpha"/>
    <ReducedPageHeader :id="cacheKeys.header" />

    <CarisCombined v-if="showCaris" />

    <DiscountDialog
      v-if="shouldShowDiscountDialog"
      :should-show="true"
      :headline="discountDialogFields.headline"
      :content="discountDialogFields.content"
      :fine-print="discountDialogFields.finePrint"
      @hide-dialog="hideDialog"
    />

    <UnavailableProductsDialog
      v-if="unavailableProducts"
      :active="unavailableProducts.show"
      :products="unavailableProducts.products"
      :cart-id="unavailableProducts.cartId"
    />

    <Placeholder
      v-if="placeholders.carSelection"
      :config="placeholders.carSelection.data"
    />
    <Placeholder
      v-if="breadcrumbsConfig"
      :config="breadcrumbsConfig"
      :class="{ 'breadcrumbs--productListing': isProductListingPage }"
    />

    <main class="main">
      <aside v-if="placeholders.navigation" class="navigation">
        <Placeholder :config="placeholders.navigation" />
      </aside>

      <aside
        v-if="placeholders.sideNavigation && !placeholders.navigation"
        class="navigation side-navigation"
      >
        <Placeholder :config="placeholders.sideNavigation" />
      </aside>

      <section
        v-if="placeholders.content"
        :class="[
          'content',
          { 'content--narrow': isNarrowContent },
          { 'content--left-aligned': isCategorySearchEnabled },
        ]"
      >
        <Placeholder
          v-if="placeholders.preContent"
          :config="placeholders.preContent"
        />

        <div
          v-if="layoutParams.showPageTitle"
          class="contentPageTitle--sideNav"
          :class="{
            contentPageTitle: true,
            'contentPageTitle--productListing': isProductListingPage,
          }"
        >
          <div class="titleWrapper">
            <Placeholder
              v-if="placeholders.pageTitleLeft"
              :config="placeholders.pageTitleLeft"
            />
          </div>

          <div class="titleWrapper">
            <Placeholder
              v-if="placeholders.pageTitleRight"
              :config="placeholders.pageTitleRight"
            />
          </div>
        </div>

        <div class="plpFiltersWrapper">
          <Placeholder
            v-if="placeholders.plpFilters && !isSeoPage"
            :config="placeholders.plpFilters"
          />
        </div>

        <template v-if="placeholders.content">
          <Placeholder v-if="Pdp21OrPlp21Config" :config="Pdp21OrPlp21Config" />

          <Placeholder
            v-else
            :config="placeholders.content"
            :modifier="
              placeholders.navigation || placeholders.sideNavigation
                ? undefined
                : 'flex'
            "
          />
        </template>

        <LazyHydrate when-visible>
          <Placeholder
            v-if="placeholders.postContent"
            :config="placeholders.postContent"
          />
        </LazyHydrate>
      </section>
    </main>

    <template v-if="isDeshop && isHomepage && !isAlpha">
      <picture class="default-template__mobile-app-image-picture">
        <source
          :srcset="resolveFile('images/default/app-banner_mobile.png')"
          media="(max-width: 768px)"
        />
        <source
          :srcset="resolveFile('images/default/app-banner_desktop.png')"
          media="(min-width: 769px)"
        />
        <img
          class="default-template__mobile-app-image"
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
          alt="Mobile Applications"
          width="1200"
          height="300"
          loading="lazy"
          @click="redirectToMobileApplication"
        />
      </picture>

      <div class="default-template__banners">
        <div
          v-for="banner in banners"
          :key="banner"
          class="default-template__banner"
        >
          <picture>
            <source
              :srcset="resolveFile('images/default/' + banner + '.jpg')"
            />
            <img
              class="default-template__banner-image"
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
              alt="Banner Image"
              width="816"
              height="520"
              loading="lazy"
            />
          </picture>
          <div class="default-template__banner-header">
            {{ $t(`default-template.${banner}.header`) }}
          </div>
          <div class="default-template__banner-text">
            {{ $t(`default-template.${banner}.text`) }}
          </div>
        </div>
      </div>
    </template>

    <LinkBox v-if="preFooterConfig" :items="preFooterConfig"></LinkBox>

    <LazyHydrate when-visible>
      <PageFooter :id="cacheKeys.footer" />
    </LazyHydrate>

    <ViewportBottom />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';
import LazyHydrate from 'vue-lazy-hydration';
import LoginModal from 'Components/03-organisms/login-modal/LoginModal.vue';

import 'Src/images/favicon';
import 'focus-visible';

import './images/app-banner_desktop.png';
import './images/app-banner_mobile.png';
import './images/fast-delivery.jpg';
import './images/on-site.jpg';
import './images/workshop.jpg';

import globalMixin from 'Libs/mixins/globalMixin';
import { updateViewportMixin } from 'Libs/mixins/updateViewportMixin';
import openLink from 'Libs/helpers/open-link';

import Placeholder from 'Components/01-atoms/placeholder/Placeholder.vue';
import ViewportBottom from 'Components/02-molecules/viewport-bottom/ViewportBottom.vue';
import ReducedPageHeader from 'Components/03-organisms/reduced-page-header/ReducedPageHeader.vue';
import PageFooter from 'Components/03-organisms/page-footer/PageFooter.vue';
import CarisCombined from 'Components/03-organisms/caris-combined/CarisCombined';
import DiscountDialog from 'Components/03-organisms/discount-dialog/DiscountDialog';
import UnavailableProductsDialog from 'Components/03-organisms/unavailable-products-dialog/UnavailableProductsDialog';
import LinkBox from 'Components/02-molecules/link-box/LinkBox.vue';

export default {
  name: 'DefaultTemplate',
  components: {
    ReducedPageHeader,
    CarisCombined,
    DiscountDialog,
    PageFooter,
    Placeholder,
    ViewportBottom,
    LazyHydrate,
    UnavailableProductsDialog,
    LinkBox,
    LoginModal
  },
  mixins: [globalMixin, updateViewportMixin],
  computed: {
    ...mapGetters('core', {
      layoutParams: 'getLayoutParams',
      isDeshop: 'isDeshop',
    }),
    ...mapState('core', {
      isBot: ({ isBot }) => isBot,
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
      isCategorySearchEnabled: ({ salesChannel: { config } }) =>
        config.isCategorySearchEnabled || false,
      placeholders: (state) => state.placeholders,
      baseUrl: ({ salesChannel }) => salesChannel.baseUrl,
      isHomepage({ requestData: { requestUrl } }) {
        return new URL(requestUrl).pathname === '/';
      },
      isSeoPage() {
        const pageType = get(this.placeholders, 'content.component');
        return pageType === 'CarCategories' || pageType === 'BrandCategories';
      },
      isCarSelected: (state) => {
        try {
          return JSON.parse(state.requestData.selectedCar).car_type_number > 0;
        } catch {
          return false;
        }
      },
      cacheKeys(state) {
        const { salesChannelId } = state.salesChannel;
        const { reducedHeader, reducedFooter } = this.layoutParams;

        const updatedAt = get(this.placeholders, 'menu.data.seoMenu.updatedAt');

        const searchBot = `bot${this.isBot}`;

        const promiseBannerVisible = get(state, 'promiseBanner.visible', false);

        return {
          header: [
            salesChannelId,
            reducedHeader,
            updatedAt,
            searchBot,
            promiseBannerVisible,
          ].join('.'),
          footer: [salesChannelId, reducedFooter, searchBot].join('.'),
        };
      },
    }),
    ...mapState('renderingSalesChannelConfig', {
      isPlp21Enabled: ({ isPlp21Enabled }) => isPlp21Enabled,
    }),
    ...mapState('header', {
      discountDialogFields({ discount }) {
        return discount?.fields || {};
      },
      unavailableProducts: ({ unavailableProducts }) =>
        unavailableProducts || {},
    }),
    ...mapGetters('header', ['shouldShowDiscountDialog']),
    showCarSelectionBar() {
      if (this.isCarSelected) {
        return false;
      } else if (
        (this.isPage('ProductDetails') && this.productNeedsCarSelection()) ||
        (this.isPage('ProductList') && this.plpNeedsCarSelection())
      ) {
        return true;
      }
      return false;
    },
    isNarrowContent() {
      return this.placeholders.sideNavigation;
    },
    showCaris() {
      const { reducedHeader } = this.layoutParams;

      return !this.isMobileSalesChannel && !reducedHeader;
    },
    isProductListingPage() {
      const type = get(this.placeholders, 'content.data.plp.pageType');
      return ['productListing', 'search'].indexOf(type) !== -1;
    },
    Pdp21OrPlp21Config() {
      const { component, data } = this.placeholders.content;

      if (component === 'ProductDetails') {
        return { component: 'ProductDetails21', data };
      } else if (component === 'ProductList') {
        return this.Plp21Config;
      }

      return false;
    },
    Plp21Config() {
      const { data } = this.placeholders.content;
      if (this.isPlp21Enabled) {
        return {
          component: 'ProductList21',
          data,
        };
      }

      return false;
    },
    breadcrumbsConfig() {
      if (this.placeholders.breadcrumbs) {
        return this.placeholders.breadcrumbs;
      }
      if (
        this.placeholders.content &&
        this.placeholders.content.data &&
        this.placeholders.content.data.breadcrumbs
      ) {
        return {
          component: 'Breadcrumbs',
          data: this.placeholders.content.data.breadcrumbs,
        };
      }
      return false;
    },
    preFooterConfig() {
      if (this.placeholders.preFooter?.data?.linkboxes.length > 0) {
        return this.placeholders.preFooter.data.linkboxes;
      }
      return false;
    },
    banners() {
      return ['fast-delivery', 'on-site', 'workshop'];
    },
    isAlpha() {
      return this.baseUrl && this.baseUrl.includes('alpha');
    },
  },
  methods: {
    ...mapMutations('header', {
      hideDialog: 'HIDE_DISCOUNT_POPUP',
    }),
    isPage(pageName) {
      return pageName === get(this.placeholders, 'content.component');
    },
    productNeedsCarSelection() {
      try {
        return this.placeholders.content.data.pdpCarBar.key;
      } catch {
        return false;
      }
    },
    plpNeedsCarSelection() {
      try {
        return this.placeholders.content.data.plp.hasCarSpecificProducts;
      } catch {
        return false;
      }
    },
    // TODO: After ".default-template__mobile-app-image-picture" is shown, test this
    redirectToMobileApplication() {
      const trustedShopsLink = 'https://app.adjust.com/nduedv7';
      openLink(trustedShopsLink);
    },
  },
};
</script>

<style lang="scss">
@import 'variables/colors';
@import 'variables/misc';
@import 'variables/fonts';
@import 'variables/spacing';
@import 'variables/theme';
@import 'variables/breakpoints';
@import 'utils/index';

.js-focus-visible :focus:not([data-focus-visible-added]) {
  outline: none;
}

.js-focus-visible [data-focus-visible-added]:not(input) {
  outline: 2px solid var(--color-tory-blue) !important;
}

[id^='trustbadge-container'] {
  z-index: 99999 !important;
}
</style>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.defaultTemplate {
  background-color: var(--color-white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  @include pageWidth;
  @include pagePadding;

  padding-top: 24px;
  flex-grow: 1;
  display: flex;
  width: 100%;

  flex-direction: column;
  min-height: calc(50vh);

  @media #{$_mediaLUp} {
    flex-direction: row;
  }

  @media #{$_mediaMDown} {
    padding-top: 0;
  }
}

.default-template__mobile-app-image-picture {
  @include pageWidth;

  margin-top: var(--space-3);
  padding: 0 var(--space-2);
}

.default-template__banners {
  display: flex;
  margin-top: var(--space-1);
  align-items: center;
  align-self: center;
  max-width: 1300px;
}

.default-template__banner {
  display: flex;
  flex-direction: column;
  margin: var(--space-3);
  width: 33%;
}

.default-template__banner-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.default-template__banner-header {
  margin: var(--space-2) 0 var(--space-1);
  font-weight: 600;
}

.default-template__banner-text {
  width: 100%;
}

@media #{$_mediaMDown} {
  .default-template__banners {
    flex-direction: column;
  }

  .default-template__banner {
    width: 100%;
    padding: 0 var(--space-2);
  }
}

.default-template__mobile-app-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.mobileMenuTransitionInitial {
  display: none;
  opacity: 0;
  transform: translateX(-50%);

  &::before {
    opacity: 0;
    transition: opacity var(--time-XS) ease-in-out;
  }
}

.mobileMenuTransitionGoal {
  opacity: 1;
  transform: translateX(0);

  &::before {
    opacity: 1;
    transition: opacity var(--time-XS) ease-in-out;
  }
}

.mobileMenuTransitionActive {
  display: block;
  transition: opacity var(--time-S) ease-in-out, transform var(--time-S) ease-in;

  &::before {
    transition: opacity var(--time-XS) ease-in-out;
  }
}

.navigation {
  @media #{$_mediaLUp} {
    flex-shrink: 0;
    margin-right: 50px;
  }
}

.side-navigation {
  width: 321px;

  @media #{$_mediaLUp} {
    margin-right: 0;
  }

  @media #{$_mediaLDown} {
    display: none;
  }

  @media all and (min-width: 1001px) and (max-width: 1300px) {
    width: 220px;
    margin-right: var(--space-3);
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .breadcrumbs {
    align-self: flex-start;
  }

  @media #{$_mediaLUp} {
    flex-grow: 1;
    width: 100%;

    &.content--narrow {
      max-width: 947px;
    }

    &.content--left-aligned {
      align-items: flex-start;
    }
  }
}

.contentPageTitle {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: 8px;
  padding-bottom: 4px;
  flex-wrap: wrap;
}

.titleWrapper {
  padding: 6px 0;
  max-width: 100%;
}

.plpFiltersWrapper {
  width: 100%;
}

@media #{$_mediaMUp} {
  .contentPageTitle {
    padding-top: 6px;
    padding-bottom: 6px;

    &--productListing {
      padding-top: 0;
    }
  }
}

@media #{$_mediaLUp} {
  .contentPageTitle {
    padding-top: 28px;
    padding-bottom: 20px;
    flex-wrap: nowrap;

    &--productListing,
    &--sideNav {
      padding-top: 10px;

      .titleWrapper {
        padding-top: 0;
      }
    }
  }

  .breadcrumbs {
    align-self: flex-start;

    &--productListing {
      padding-bottom: 18px;
    }
  }
}

@media print {
  .defaultTemplate,
  .content {
    display: block;
  }
}
</style>
