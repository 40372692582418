var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal","order":_vm.order,"data-preload":_vm.$options.name}},[(_vm.isActive)?_c('div',{class:[_vm.classes, { 'is-black': _vm.isBlack }]},[(_vm.clickOutside && _vm.isActive)?_c('div',{staticClass:"overlay",on:{"click":function($event){return _vm.hide()}}}):_vm._e(),_vm._v(" "),_c('DialogFrame',{staticClass:"frame",class:{
        'raised-frame': _vm.isIosApp
      },attrs:{"title":_vm.title,"on-close-handler":_vm.hide,"is-black":_vm.isBlack,"is-rebranding-channel":_vm.isRebrandingChannel},scopedSlots:_vm._u([(_vm.buttons.length)?{key:"buttons",fn:function(){return [_c('div',{staticClass:"buttonsSection",class:{
            'buttonsSection--multi': _vm.buttons.length > 1,
          }},_vm._l((_vm.buttons),function(item,index){return _c('Button',_vm._b({key:("button-" + index),class:[
              ("button--" + (item.button)),
              ("qa-DialogButton__" + (item.button)) ],attrs:{"modifiers":_vm.isRebrandingChannel
                ? ['rebranding' ].concat( item.config.modifiers)
                : [].concat( item.config.modifiers )}},'Button',item.config,false))}),1)]},proxy:true}:null],null,true)},[_c('div',{class:{ slotFrame: _vm.useFramePadding }},[_vm._t("default")],2)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }