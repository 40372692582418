<template>
  <portal to="modal" :order="order" :data-preload="$options.name">
    <div v-if="isActive" :class="[classes, { 'is-black': isBlack }]">
      <div
        v-if="clickOutside && isActive"
        class="overlay"
        @click="hide()"
      ></div>

      <DialogFrame
        :title="title"
        class="frame"
        :class="{
          'raised-frame': isIosApp
        }"
        :on-close-handler="hide"
        :is-black="isBlack"
        :is-rebranding-channel="isRebrandingChannel"
      >
        <div :class="{ slotFrame: useFramePadding }">
          <slot />
        </div>

        <template v-if="buttons.length" v-slot:buttons>
          <div
            class="buttonsSection"
            :class="{
              'buttonsSection--multi': buttons.length > 1,
            }"
          >
            <Button
              v-for="(item, index) in buttons"
              :key="`button-${index}`"
              v-bind="item.config"
              :modifiers="
                isRebrandingChannel
                  ? ['rebranding', ...item.config.modifiers]
                  : [...item.config.modifiers]
              "
              :class="[
                `button--${item.button}`,
                `qa-DialogButton__${item.button}`,
              ]"
            />
          </div>
        </template>
      </DialogFrame>
    </div>
  </portal>
</template>

<script>
/*** Transitions of the Dialog has to be fully disabled since it's currently causing a bug with closing the Dialogs ***/

import DialogFrame from 'Components/01-atoms/dialog-frame/DialogFrame.vue';
import Button from 'Components/01-atoms/button/ButtonBase.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import scrollMixin from 'Libs/mixins/scrollMixin';

export default {
  name: 'Dialog',
  components: {
    Button,
    DialogFrame,
  },
  mixins: [globalMixin, scrollMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Number,
      default: 0,
    },
    onClose: {
      type: [Function, Boolean],
      default: false,
    },
    confirmButton: {
      type: Object,
      default: null,
    },
    cancelButton: {
      type: Object,
      default: null,
    },
    thirdButton: {
      type: Object,
      default: null,
    },
    clickOutside: {
      type: Boolean,
      default: true,
    },
    useFramePadding: {
      type: Boolean,
      default: true,
    },
    isBlack: {
      type: Boolean,
      default: false,
    },
    isRebrandingChannel: {
      type: Boolean,
      default: false,
    },
    isIosApp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active,
    };
  },
  computed: {
    buttons() {
      return [
        !!this.confirmButton && {
          button: 'confirm',
          config: {
            modifier: 'primary',
            ...this.confirmButton,
            onClick: ($event) => {
              if (this.confirmButton.onClick) {
                const response = this.confirmButton.onClick($event);
                if (response === 'dontClose') {
                  return;
                }
              }
              this.hide();
            },
          },
        },
        !!this.cancelButton && {
          button: 'cancel',
          config: {
            modifier: 'secondary',
            ...this.cancelButton,
            onClick: ($event) => {
              this.cancelButton.onClick && this.cancelButton.onClick($event);
              this.hide();
            },
          },
        },
        !!this.thirdButton && {
          button: 'third',
          config: {
            modifier: 'secondary',
            ...this.thirdButton,
            onClick: ($event) => {
              if (this.thirdButton.onClick) {
                const response = this.thirdButton.onClick($event);
                if (response === 'dontClose') {
                  return;
                }
              }
              this.hide();
            },
          },
        },
      ].filter((element) => !!element.config);
    },
  },
  methods: {
    show() {
      this.isActive = true;
      this.disableBodyScrolling();
    },
    hide() {
      this.isActive = false;
      this.enableBodyScrolling();

      this.$emit('hide');
    },
    onCloseHandler() {
      this.onClose?.();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  opacity: 1;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.3);
}

.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 100%;
  max-height: calc(100vh - 40px);
  max-width: 600px;
  z-index: 40;
  scroll-padding-top: 50px;
}

.raised-frame {
  max-height: calc(100vh - 88px);
}

.buttonsSection {
  display: flex;
  flex-flow: row nowrap;
  padding: var(--space-2);
  align-items: center;
  border-top: solid 1px var(--color-alto);
  justify-content: center;

  &--multi {
    justify-content: end;
    flex-direction: row-reverse;

    .button:last-of-type {
      margin-left: var(--space-1);
    }

    .button {
      &--cancel,
      &--confirm {
        width: 147px;
        margin-right: var(--space-1);
        text-transform: initial;
      }

      &--third {
        width: 260px;
        margin-right: var(--space-1);
        border-color: var(--color-white);
        background-color: var(--color-nero);
        color: var(--color-white);
        text-transform: initial;
      }
    }

    @media #{$_mediaMDown} {
      flex-wrap: wrap;

      .button {
        width: 100%;
        margin: 0;
        margin-bottom: var(--space-1);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.button {
  &--confirm {
    width: 147px;
    text-transform: initial;
  }
}

.slotFrame {
  padding: var(--space-2);
  font-size: var(--font-size-S);
  line-height: 16px;
}
</style>
