var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes,attrs:{"data-preload":[_vm.$options.name]}},[_c('form',{staticClass:"caris-license-new-header caris-license__form form",on:{"submit":function($event){$event.preventDefault();return _vm.doLicenseRequest.apply(null, arguments)}}},[_c('div',{staticClass:"caris-license-new-header_label"},[_vm._v("\n      "+_vm._s(_vm.$t('caris-license-new-header.headline'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"caris-license-new-header_license-plate-wrapper"},[(_vm.countryCode !== 'FR')?[_c('div',{staticClass:"caris-license-new-header_license-plate-EU"},[_c('Icon',{staticClass:"caris-license-new-header_eu-icon",attrs:{"name":"eu_stars","original":true,"inline":false,"lazy":true}}),_vm._v(" "),_c('span',{staticClass:"caris-license-new-header_country-code"},[_vm._v(_vm._s(_vm.plateIdentifier))])],1),_vm._v(" "),_c('InputBase',{ref:"licensePlate",class:[
            'caris-license-new-header_license-plate',
            { NL: _vm.countryCode === 'NL' } ],attrs:{"gtm-class-name":'gtm--caris-license-new-header-licensePlate',"errors":_vm.errors,"placeholder":_vm.$t('caris-license-new-header.license_plate.placeholder'),"send":true,"full-name":"plate_nr","hide-optional":true,"type":"text","value":_vm.form.licensePlateValue,"on-enter":_vm.doLicenseRequest,"attr":{
            autocomplete: 'off',
            autocorrect: 'off',
            autocapitalize: 'off',
            spellcheck: 'false',
          }},on:{"input":_vm.handleChange}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"caris-license-new-header_fields"},[_c('CarisDropdown',{ref:"multiCarDropdown",class:[
            'caris-license-new-header_field',
            'caris-license-new-header_field--full' ],attrs:{"disabled":Object.keys(_vm.carList).length === 0,"car-list":_vm.carList,"placeholder":_vm.$t('caris-license-new-header.type_mine.dropdown_label')}})],1),_vm._v(" "),(_vm.isMobile)?_c('CarisTypeMineSearch',{ref:"mine-search",staticClass:"caris-license-new-header_form__mine-search"}):_c('div',[_c('div',{staticClass:"caris-license-new-header_button-wrapper"},[_c('button',{staticClass:"caris-license-new-header_where-to-find-button",class:[_vm.qaClassName + 'whereToFindButton'],attrs:{"type":"button"}},[_vm._v("\n            "+_vm._s(_vm.$t('caris-license-new-header.gray_cart'))+"\n          ")])]),_vm._v(" "),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.action),expression:"action"}],staticClass:"caris-license-new-header_form__select-car-button gtm--caris-license-new-header-selectCarButton",class:_vm.qaClassName + 'selectCarButton',attrs:{"title":_vm.$t('caris-license-new-header.select_new_car'),"modifier":"tertiary","type":"submit"}})],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }