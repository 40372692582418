var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.classes, _vm.isSecondary],attrs:{"data-preload":[_vm.$options.name]}},[(_vm.variant === 'secondary')?_c('div',{staticClass:"header-wrapper"},[_c('span',{staticClass:"headline"},[_vm._v("\n      "+_vm._s(_vm.$t('pdp-badges.any_questions'))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"subtitle margin-bottom"},[_vm._v("\n      "+_vm._s(_vm.$t('pdp-badges.we_help'))+"\n    ")]),_vm._v(" "),(_vm.contactUsInfo.phone && !_vm.contactUsInfo.hideSupportPhoneNumber)?_c('MaskedLink',{staticClass:"phone-number",attrs:{"redirect-to":("tel:" + (_vm.contactUsInfo.phone)),"text":_vm.contactUsInfo.formattedPhone}}):_vm._e(),_vm._v(" "),(_vm.contactUsInfo.email)?_c('MaskedLink',{staticClass:"email",attrs:{"redirect-to":("mailto:" + (_vm.contactUsInfo.email)),"text":_vm.contactUsInfo.email}}):_vm._e(),_vm._v(" "),(_vm.contactUsInfo.openingWeekdays && !_vm.hideOpeningWeekends)?_c('span',{staticClass:"subtitle"},[_vm._v("\n      "+_vm._s(_vm.contactUsInfo.openingWeekdays)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.contactUsInfo.openingWeekend && !_vm.hideOpeningWeekends)?_c('span',{staticClass:"subtitle"},[_vm._v("\n      "+_vm._s(_vm.contactUsInfo.openingWeekend)+"\n    ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.variant === 'primary')?_c('div',{staticClass:"content-primary"},[(!_vm.isBusinessCustomer)?_c('div',{staticClass:"freeShipping"},[_c('ShippingTruck',{staticClass:"truck",attrs:{"width":"22","height":"29","original":true}}),_vm._v("\n      "+_vm._s(_vm.$t('pdp-badges.free_shipping_min', {
          '%freeDeliveryMin%': _vm.freeDeliveryMin,
          '%currency%': _vm.currency,
        }))+"\n    ")],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"badges"},[_c('div',{staticClass:"badge"},[_c('ReloadSmall',{staticClass:"badgeIcon",attrs:{"width":"24","height":"19","original":true}}),_vm._v(" "),_c('div',{staticClass:"badgeText"},[_vm._v("\n          "+_vm._s(_vm.$t('pdp-badges.return_guarantee', {
              '%daysToReturn%': _vm.daysToReturn,
            }))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"badge"},[_c('ArrowBox',{staticClass:"badgeIcon return",attrs:{"width":"26","height":"28","original":true}}),_vm._v(" "),_c('div',{staticClass:"badgeText"},[_vm._v("\n          "+_vm._s(_vm.$t('pdp-badges.free_return'))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"badge"},[_c('CustomerService',{staticClass:"badgeIcon",attrs:{"width":"26","height":"30","original":true}}),_vm._v(" "),_c('div',{staticClass:"badgeText customer-info"},[_c('p',[_vm._v(_vm._s(_vm.$t('pdp-badges.customer_service')))]),_vm._v(" "),_c('div',{staticClass:"contact-details-customer-service"},[_c('span',{staticClass:"contact"},[(
                  _vm.contactUsInfo.phone && !_vm.contactUsInfo.hideSupportPhoneNumber
                )?_c('MaskedLink',{attrs:{"redirect-to":("tel:" + (_vm.contactUsInfo.phone)),"text":_vm.contactUsInfo.formattedPhone}}):_vm._e()],1),_vm._v(" "),(_vm.showAll)?_c('span',[_vm._v(_vm._s(_vm.$t('pdp-badges.customer_service_or')))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"contact"},[(_vm.contactUsInfo.email)?_c('MaskedLink',{attrs:{"redirect-to":("mailto:" + (_vm.contactUsInfo.email)),"text":_vm.contactUsInfo.email}}):_vm._e()],1),_vm._v(" "),(
                _vm.contactUsInfo.openingTimes &&
                !_vm.contactUsInfo.hideSupportPhoneNumber
              )?_c('p',[(_vm.$te('pdp-badges.customer_service_schedule'))?_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('pdp-badges.customer_service_schedule'))+"\n              ")]):_vm._e(),_vm._v("\n              "+_vm._s(_vm.contactUsInfo.openingTimes)+"\n            ")]):_vm._e()])])],1)])]):_c('div',{staticClass:"content-secondary"},[(!_vm.isBusinessCustomer)?_c('div',{staticClass:"badge freeShipping"},[_c('ShippingTruck',{staticClass:"badgeIcon",attrs:{"width":"32","height":"32","original":true}}),_vm._v(" "),_c('span',{staticClass:"subtitle"},[_vm._v("\n        "+_vm._s(_vm.$t('pdp-badges.free_shipping', {
            '%freeDeliveryMin%': _vm.freeDeliveryMin,
            '%currency%': _vm.currency,
          }))+"\n      ")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"badge"},[_c('ReloadSmall',{staticClass:"badgeIcon",attrs:{"width":"32","height":"32","original":true}}),_vm._v(" "),_c('span',{staticClass:"subtitle"},[_vm._v("\n        "+_vm._s(_vm.$t('pdp-badges.return_guarantee', {
            '%daysToReturn%': _vm.daysToReturn,
          }))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"badge"},[_c('ArrowBox',{staticClass:"badgeIcon return",attrs:{"width":"32","height":"32","original":true}}),_vm._v(" "),_c('span',{staticClass:"subtitle"},[_vm._v("\n        "+_vm._s(_vm.$t('pdp-badges.free_return'))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"badge"},[_c('PaymentOptionsSvg',{staticClass:"badgeIcon",attrs:{"width":"32","height":"32","original":true}}),_vm._v(" "),_c('span',{staticClass:"subtitle"},[_vm._v("\n        "+_vm._s(_vm.$t('pdp-badges.convenient_payments_options'))+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }