var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productImageSlider",class:{ 'product-image-slider--product-page': _vm.isProductPage }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewport !== 'desktop'),expression:"viewport !== 'desktop'"}],staticClass:"teaser"},[_c('Slider',{staticClass:"teaserImage",attrs:{"items":_vm.items,"show-thumbnails":false,"variant":_vm.variant},on:{"click-image":_vm.openLayer,"change-image":_vm.onChangeSliderImage}},[(_vm.variant === 'primary' && _vm.brand && _vm.brand.src)?_c('div',{staticClass:"sliderBrand"},[_c('AdvancedImage',{attrs:{"src":_vm.brand.src,"alt":_vm.brand.alt,"config":_vm.brandConfig}})],1):_vm._e()]),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.images.length > 1),expression:"images.length > 1"}],staticClass:"button",on:{"click":_vm.openLayer}},[_c('ul',{staticClass:"productImageSlider-pagination"},_vm._l((_vm.images),function(dot,i){return _c('li',{key:(i + "-" + (dot.src)),class:{
            'productImageSlider-pagination-dot': true,
            'productImageSlider-pagination-dot--highlighted':
              _vm.currentSliderImage === i + 1,
          },attrs:{"data-index":i}})}),0)])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewport === 'desktop'),expression:"viewport === 'desktop'"}],staticClass:"slider"},[_c('Slider',{attrs:{"items":_vm.items,"variant":_vm.variant},on:{"click-image":_vm.openLayer,"change-image":_vm.onChangeSliderImage}},[(_vm.variant === 'primary' && _vm.brand && _vm.brand.src)?_c('div',{staticClass:"sliderBrand"},[_c('AdvancedImage',{attrs:{"src":_vm.brand.src,"alt":_vm.brand.alt,"config":_vm.brandConfig}})],1):_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.items && _vm.variant === 'secondary' && _vm.images.length > 1),expression:"items && variant === 'secondary' && images.length > 1"}],staticClass:"button secondary",on:{"click":_vm.openLayer}},[_c('ul',{staticClass:"productImageSlider-pagination"},_vm._l((_vm.images),function(dot,i){return _c('li',{key:(i + "-" + (dot.src)),class:{
              'productImageSlider-pagination-dot': true,
              'productImageSlider-pagination-dot--secondary': true,
              'productImageSlider-pagination-dot--secondary--highlighted':
                _vm.currentSliderImage === i + 1,
            },attrs:{"data-index":i}})}),0)])])],1),_vm._v(" "),_c('Dialog',{ref:"productImageDialog",attrs:{"click-outside":true,"title":_vm.title}},[_c('Slider',{ref:"productImageDialogSlider",attrs:{"items":_vm.items,"variant":_vm.variant}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }